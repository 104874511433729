import defaultAxios from "axios";
import { message } from "antd";
const axios = defaultAxios.create({
    baseURL: "/"
});
axios.interceptors.request.use(config => {
    var _a, _b;
    if (((_a = config.url) === null || _a === void 0 ? void 0 : _a.startsWith("/v1")) || ((_b = config.url) === null || _b === void 0 ? void 0 : _b.startsWith("/v2"))) {
    }
    else {
        config.baseURL = "/api";
    }
    return config;
});
axios.interceptors.response.use((response) => {
    if (response.data && response.data.code < 0) {
        if (response.data.message) {
            message.error(response.data.message);
            console.warn(response.data.message);
        }
    }
    return response.data;
}, (error) => {
    if (error.response.status === 401) {
        location.href = `/v2/login/browser?return_to=${location.href}`;
    }
    const { data } = error.response;
    if (data && data.message) {
        message.error(data.message);
    }
    return Promise.reject(error);
});
export default axios;

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import moment from "moment";
import { totalSandBagsStore } from "./TotalSandBagsStore";
import { Button, Table } from "antd";
import { observer } from "mobx-react";
let TotalSandBags = class TotalSandBags extends React.Component {
    constructor() {
        super(...arguments);
        this.columns = [
            {
                title: "名称",
                dataIndex: "id",
                ellipsis: true,
                render: (value, record) => {
                    return <div>{record.name}</div>;
                }
            },
            {
                title: "沙子数量",
                width: 120,
                dataIndex: "sandCount"
            },
            {
                title: "创建日期",
                align: "center",
                width: 160,
                render: (value, record) => {
                    return moment(record.createdAt).format("YYYY-MM-DD");
                }
            },
            {
                title: "操作",
                key: "action",
                align: "center",
                width: 160,
                render: (value, record) => {
                    return (<div>
            <Button size="small" type="link" onClick={() => {
                        window.open(`/api/label_sand_bags/${record.id}/sands`);
                    }}>
              下载
            </Button>
          </div>);
                }
            }
        ];
    }
    async componentDidMount() {
        await totalSandBagsStore.loadData();
        await totalSandBagsStore.loadUsers();
    }
    componentWillUnmount() {
        totalSandBagsStore.resetStatus();
    }
    render() {
        return (<div>
        <Table style={{ marginTop: "20px" }} bordered={true} rowKey={record => "" + record.id} dataSource={totalSandBagsStore.filteredSandBags} columns={this.columns} pagination={{
            showSizeChanger: false
        }}/>
      </div>);
    }
};
TotalSandBags = __decorate([
    observer
], TotalSandBags);
export { TotalSandBags };

const readLine = (file, opts = {
    chunkSize: 10 * 1024
}) => {
    const reader = new FileReader();
    const read = (blob) => {
        return new Promise(resolve => {
            reader.readAsArrayBuffer(blob);
            reader.onload = e => {
                resolve(e.target.result);
            };
        });
    };
    const { chunkSize } = opts;
    let start = 0;
    let remain = new Int8Array();
    let cursor = 0;
    const next = async () => {
        let found = false;
        let data = new Int8Array();
        let lineStart = cursor;
        let lineLength = 0;
        if (remain.byteLength) {
            for (let i = 0; i < remain.byteLength; i++) {
                if (remain[i] == 10 || remain[i] == 13) {
                    found = true;
                    cursor += lineLength + 1;
                    remain = remain.slice(i + 1);
                    break;
                }
                else {
                    lineLength++;
                }
            }
            if (!found) {
                remain = new Int8Array();
            }
        }
        else if (start > file.size) {
            return {
                done: true,
                value: null
            };
        }
        while (!found) {
            const blob = file.slice(start, start + chunkSize);
            const buf = await read(blob);
            data = new Int8Array(buf);
            if (data.byteLength === 0) {
                found = true;
                break;
            }
            for (let i = 0; i < data.byteLength; i++) {
                if (data[i] == 10 || data[i] == 13) {
                    found = true;
                    cursor += lineLength + 1;
                    remain = data.slice(i + 1);
                    break;
                }
                else {
                    lineLength++;
                }
            }
            start += chunkSize;
        }
        return {
            done: false,
            value: await file.slice(lineStart, lineStart + lineLength).text()
        };
    };
    return {
        [Symbol.asyncIterator]() {
            return {
                next
            };
        }
    };
};
export default readLine;

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import moment from "moment";
import { CSVLink } from "react-csv";
import { toJS } from "mobx";
import { Select, DatePicker, Button, Skeleton, Divider, Tooltip } from "antd";
import { DownloadOutlined, SearchOutlined, UpCircleFilled, UpCircleOutlined } from "@ant-design/icons";
import { taskCompositeAuditStore, OrderBy } from "./TaskCompositeAuditStore";
import { observer } from "mobx-react";
import "./TaskCompositeAudit.less";
import { ILabelPackageAssignment } from "definition/entity/label-package-assignment";
import { transformLabelPackageStatus } from "util/status-transform";
import { ILabelPackage } from "definition/entity/label-package";
const { RangePicker } = DatePicker;
let TaskCompositeAudit = class TaskCompositeAudit extends React.Component {
    constructor() {
        super(...arguments);
        this.columns = [
            {
                title: "任务名称",
                key: "taskName"
            },
            {
                title: "标注进度",
                key: "labeled"
            },
            {
                title: "标注剩余天数-标注要求完成日期",
                key: "labelDeadline"
            },
            {
                title: "复审进度",
                key: "archived"
            },
            {
                title: "任务剩余天数-任务要求完成日期",
                key: "taskDeadline"
            },
            {
                title: "分包名称",
                key: "packageName"
            },
            {
                title: "分包状态",
                key: "packageStatus"
            },
            {
                title: "标注人员",
                key: "labelUser"
            },
            {
                title: "复审人员",
                key: "reviewUser"
            },
            {
                title: "分包标注进度",
                key: "labelProcess"
            }
        ];
        this.disabledDate = (current) => {
            let { calendarDate } = taskCompositeAuditStore.status;
            if (!calendarDate || calendarDate.length === 0) {
                return false;
            }
            let startTime = calendarDate[0];
            const start = startTime && startTime.format("YYYYMMDD") > current.format("YYYYMMDD");
            const end = startTime &&
                moment(startTime)
                    .add(30, "days")
                    .format("YYYYMMDD") < current.format("YYYYMMDD");
            return start || end;
        };
    }
    componentDidMount() {
        taskCompositeAuditStore.loadTasks();
    }
    render() {
        const status = taskCompositeAuditStore.status;
        return (<div className="composite-audit">
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        }}>
          <div>
            <RangePicker disabledDate={this.disabledDate} value={toJS(status.calendarDate)} onCalendarChange={date => {
            status.calendarDate = date;
        }} onChange={date => {
            status.calendarDate = date;
        }}/>
            <Select placeholder="请选择任务" allowClear showSearch mode="multiple" value={toJS(status.curTaskIds)} style={{ width: 300, marginLeft: 10 }} onChange={value => {
            status.curTaskIds = value;
        }} filterOption={(input, option) => {
            if (option && option.props && option.props.title) {
                return option.props.title === input || option.props.title.indexOf(input) !== -1;
            }
            else {
                return true;
            }
        }}>
              {toJS(taskCompositeAuditStore.filterTasks).map(task => (<Select.Option key={task === null || task === void 0 ? void 0 : task.id} title={task.name} value={task === null || task === void 0 ? void 0 : task.id}>
                  {task.name}
                </Select.Option>))}
            </Select>
            <Button style={{
            marginLeft: 10
        }} icon={<SearchOutlined />} disabled={taskCompositeAuditStore.isUnableSearch} onClick={() => {
            taskCompositeAuditStore.loadData();
        }}></Button>
          </div>

          <CSVLink data={toJS(status.exportCSVData)} filename={status.CSVFilename}>
            <Button type="primary" shape="round" icon={<DownloadOutlined />} disabled={taskCompositeAuditStore.isUnableDownload} onClick={() => taskCompositeAuditStore.downTaskCompositeAuditFile()}/>
          </CSVLink>
        </div>
        <div className="audit-table-wrapper">
          {status.loading ? (<Skeleton active/>) : (<React.Fragment>
              <div className="audit-table">
                <div className="audit-table-body">
                  <table>
                    <thead className="audit-table-thead">
                      <tr>
                        {this.columns.map(column => {
            if ([OrderBy.LabelUser, OrderBy.ReviewUser].includes(column.key)) {
                return (<th key={column.key}>
                                {column.title}
                                &nbsp;
                                {status.orderBy.key === column.key && status.orderBy.order ? (<UpCircleFilled onClick={() => {
                    status.orderBy = { key: column.key, order: !status.orderBy.order };
                }}/>) : (<UpCircleOutlined onClick={() => {
                    status.orderBy = { key: column.key, order: !status.orderBy.order };
                }}/>)}
                              </th>);
            }
            else if (["taskDeadline", "labelDeadline"].includes(column.key)) {
                return (<th key={column.key}>
                                <div>{column.title.split("-")[0]}</div>
                                <div>{`(${column.title.split("-")[1]})`}</div>
                              </th>);
            }
            else {
                return <th key={column.key}>{column.title}</th>;
            }
        })}
                      </tr>
                    </thead>
                    <tbody className="audit-table-tbody">
                      {toJS(taskCompositeAuditStore.transformedAudits).map((item, index) => (<tr key={index}>
                          <td>
                            <div className="cell-column">
                              <span>{item.taskName}</span>
                            </div>
                          </td>
                          <td>
                            <div style={{
            textAlign: "center"
        }}>
                              <span>
                                {((item.labelPackage.filter(i => [
            ILabelPackage.Status.Labeled,
            ILabelPackage.Status.AssignReview,
            ILabelPackage.Status.Reviewing,
            ILabelPackage.Status.Reviewed,
            ILabelPackage.Status.AssignFinalReview,
            ILabelPackage.Status.FinalReviewing,
            ILabelPackage.Status.FinalReviewed,
            ILabelPackage.Status.Archived
        ].includes(i.status)).length /
            item.labelPackage.length) *
            100).toFixed(2)}
                                %
                              </span>
                              <Divider type="vertical"/>
                              <span>{`(${item.labelPackage.filter(i => [
            ILabelPackage.Status.Labeled,
            ILabelPackage.Status.AssignReview,
            ILabelPackage.Status.Reviewing,
            ILabelPackage.Status.Reviewed,
            ILabelPackage.Status.AssignFinalReview,
            ILabelPackage.Status.FinalReviewing,
            ILabelPackage.Status.FinalReviewed,
            ILabelPackage.Status.Archived
        ].includes(i.status)).length}/${item.labelPackage.length})`}</span>
                            </div>
                          </td>
                          <td>
                            <div className="cell-column" style={{
            textAlign: "center"
        }}>
                              <span>
                                {item.labelDeadline ? (<React.Fragment>
                                    <div>{moment(item.labelDeadline).diff(moment(), "day") + 1}</div>
                                    <div>{`(${moment(item.labelDeadline).format("YYYY-MM-DD")})`}</div>
                                  </React.Fragment>) : ("-")}
                              </span>
                            </div>
                          </td>
                          <td>
                            <div style={{
            textAlign: "center"
        }}>
                              <span>
                                {((item.labelPackage.filter(i => [
            ILabelPackage.Status.Reviewed,
            ILabelPackage.Status.FinalReviewed,
            ILabelPackage.Status.Archived
        ].includes(i.status)).length /
            item.labelPackage.length) *
            100).toFixed(2)}
                                %
                              </span>
                              <Divider type="vertical"/>
                              <span>
                                {`(${item.labelPackage.filter(i => [
            ILabelPackage.Status.Reviewed,
            ILabelPackage.Status.FinalReviewed,
            ILabelPackage.Status.Archived
        ].includes(i.status)).length}/${item.labelPackage.length})`}
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="cell-column" style={{
            textAlign: "center"
        }}>
                              <span>
                                {item.taskDeadline ? (<React.Fragment>
                                    <div>{moment(item.taskDeadline).diff(moment(), "day") + 1}</div>
                                    <div>{`(${moment(item.taskDeadline).format("YYYY-MM-DD")})`}</div>
                                  </React.Fragment>) : ("-")}
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="cell-column" style={{
            display: "grid",
            gridTemplateRows: `repeat(${item.labelPackage.length}, 1fr)`,
            textAlign: "center"
        }}>
                              {item.labelPackage.map(_package => (<span key={_package.id}>{_package.packageName}</span>))}
                            </div>
                          </td>
                          <td>
                            <div className="cell-column" style={{
            display: "grid",
            gridTemplateRows: `repeat(${item.labelPackage.length}, 1fr)`,
            textAlign: "center"
        }}>
                              {item.labelPackage.map(_package => (<span key={_package.id}>{transformLabelPackageStatus(_package)}</span>))}
                            </div>
                          </td>
                          <td>
                            <div className="cell-column" style={{
            display: "grid",
            gridTemplateRows: `repeat(${item.labelPackage.length}, 1fr)`,
            textAlign: "center"
        }}>
                              {item.labelPackage.map(_package => {
            var _a, _b, _c;
            const labelUser = _package.assignments
                .filter(i => i.type === ILabelPackageAssignment.Type.Label)
                .pop();
            return (<span key={_package.id}>
                                    {(labelUser === null || labelUser === void 0 ? void 0 : labelUser.isVisible) ? (<Tooltip placement="top" title={`${(_a = labelUser === null || labelUser === void 0 ? void 0 : labelUser.user) === null || _a === void 0 ? void 0 : _a.fullname}(${(_b = labelUser === null || labelUser === void 0 ? void 0 : labelUser.user) === null || _b === void 0 ? void 0 : _b.email})`}>
                                        {(_c = labelUser === null || labelUser === void 0 ? void 0 : labelUser.user) === null || _c === void 0 ? void 0 : _c.fullname}
                                      </Tooltip>) : ("-")}
                                  </span>);
        })}
                            </div>
                          </td>
                          <td>
                            <div className="cell-column" style={{
            display: "grid",
            gridTemplateRows: `repeat(${item.labelPackage.length}, 1fr)`,
            textAlign: "center"
        }}>
                              {item.labelPackage.map(_package => {
            var _a, _b, _c;
            const reviewUser = _package.assignments
                .filter(i => i.type === ILabelPackageAssignment.Type.Review)
                .pop();
            return (<span key={_package.id}>
                                    {(reviewUser === null || reviewUser === void 0 ? void 0 : reviewUser.isVisible) ? (<Tooltip placement="top" title={`${(_a = reviewUser === null || reviewUser === void 0 ? void 0 : reviewUser.user) === null || _a === void 0 ? void 0 : _a.fullname} (${(_b = reviewUser === null || reviewUser === void 0 ? void 0 : reviewUser.user) === null || _b === void 0 ? void 0 : _b.email})`}>
                                        {(_c = reviewUser === null || reviewUser === void 0 ? void 0 : reviewUser.user) === null || _c === void 0 ? void 0 : _c.fullname}
                                      </Tooltip>) : ("-")}
                                  </span>);
        })}
                            </div>
                          </td>
                          <td>
                            <div className="cell-column" style={{
            display: "grid",
            gridTemplateRows: `repeat(${item.labelPackage.length}, 1fr)`,
            textAlign: "center"
        }}>
                              {item.labelPackage.map(_package => _package.status === ILabelPackage.Status.Labelling ? (<span key={_package.id}>
                                    <span style={{
            borderBottom: "none"
        }}>
                                      {((_package.labeledResourceLen / _package.totalResourceLen) * 100).toFixed(2)}%
                                    </span>
                                    <Divider type="vertical"/>
                                    <span>{`(${_package.labeledResourceLen}/${_package.totalResourceLen})`}</span>
                                  </span>) : (<span key={_package.id}>-</span>))}
                            </div>
                          </td>
                        </tr>))}
                    </tbody>
                  </table>
                </div>
                {toJS(status.compositeAudits).length === 0 && (<div className="audit-table-placeholder">
                    <div className="audit-empty audit-empty-normal">
                      <div className="audit-empty-image">
                        <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                          <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                            <ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                            <g fillRule="nonzero" stroke="#D9D9D9">
                              <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                              <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA"></path>
                            </g>
                          </g>
                        </svg>
                      </div>
                      <p className="audit-empty-description">暂无数据</p>
                    </div>
                  </div>)}
              </div>
            </React.Fragment>)}
        </div>
      </div>);
    }
};
TaskCompositeAudit = __decorate([
    observer
], TaskCompositeAudit);
export { TaskCompositeAudit };

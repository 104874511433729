var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import * as _ from "lodash";
import moment from "moment";
import BasicStore from "store/BasicStore";
import { action, observable, computed, toJS } from "mobx";
import { IAnnotationAudit } from "definition/entity/annotation-audit";
import { Api } from "api/Api";
import { LabelTaskBusiness } from "definition/entity/label-task-business";
import { message } from "antd";
import { transformLabelPackageStatus } from "util/status-transform";
class Status {
    constructor() {
        this.loading = false;
        this.userAudits = [];
        this.groups = [];
        this.users = [];
        this.auditPeriod = IAnnotationAudit.AuditPeriod.Daily;
        this.calendarDate = moment();
        this.exportCSVData = [];
        this.CSVFilename = undefined;
        this.curGroupIds = [];
        this.curUserIds = [];
    }
}
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "loading", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "userAudits", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "groups", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "users", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof IAnnotationAudit !== "undefined" && IAnnotationAudit.AuditPeriod) === "function" ? _a : Object)
], Status.prototype, "auditPeriod", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "calendarDate", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "exportCSVData", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "CSVFilename", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "curGroupIds", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "curUserIds", void 0);
class UserAuditStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
    }
    resetStatus() {
        this.status = new Status();
    }
    async loadData() {
        let auditDateString = this.auditDateString;
        if (!auditDateString) {
            return;
        }
        const { auditPeriod, curGroupIds, curUserIds } = toJS(this.status);
        try {
            this.status.loading = true;
            const userAudits = await Api.audit.getUserAudits({
                auditPeriod: auditPeriod,
                auditDateString: auditDateString,
                groupIds: curGroupIds,
                userIds: curUserIds
            });
            this.status.userAudits = userAudits.data;
            this.status.CSVFilename = `${auditDateString}_userAudits`;
        }
        catch (err) {
            message.error(err.message);
        }
        finally {
            this.status.loading = false;
        }
    }
    async loadGroups() {
        const getGroupsRes = await Api.membership.listGroups();
        this.status.groups = getGroupsRes.data;
    }
    async loadUsers() {
        const getUsersRes = await Api.user.listUsers();
        this.status.users = getUsersRes.data;
    }
    downAnnotationAuditFile() {
        const { userAudits, exportCSVData } = this.status;
        exportCSVData.length = 0;
        userAudits.forEach(audit => {
            var _a, _b, _c, _d;
            let businessType = undefined;
            let detectionCount = "-";
            let classificationCount = "-";
            let segmentCount = "-";
            let objectCount = 0;
            let trailCount = 0;
            let chunkCount = 0;
            let duration = 0;
            let segment3DCount = "-";
            switch ((_a = audit.labelTaskBusiness) === null || _a === void 0 ? void 0 : _a.type) {
                case LabelTaskBusiness.Type.Box:
                    businessType = "个（检测框）";
                    detectionCount = `${audit.detectionCount} (框)`;
                    break;
                case LabelTaskBusiness.Type.Piece:
                    businessType = "张（图片）";
                    classificationCount = `${audit.classificationCount} (张)`;
                    break;
                case LabelTaskBusiness.Type.MaskBox:
                    businessType = "个 （分割框）";
                    segmentCount = `${audit.segmentCount} (框)`;
                    break;
                case LabelTaskBusiness.Type.Object:
                    businessType = "个（关键帧）";
                    objectCount = audit.objectCount;
                    trailCount = audit.trailCount;
                    break;
                case LabelTaskBusiness.Type.Chunk:
                    businessType = "段（分类内容）";
                    chunkCount = audit.chunkCount;
                    duration = audit.duration;
                    break;
                case LabelTaskBusiness.Type.Anchor:
                    businessType = "个（时空锚对象）";
                    objectCount = audit.anchorCount;
                    trailCount = audit.anchorTrailCount;
                    break;
                case LabelTaskBusiness.Type.Cloud:
                    businessType = "个（点云）";
                    segment3DCount = `${audit.segment3dCount}(${audit.segment3dCount - audit.inheritedSegment3dCount} + ${audit.inheritedSegment3dCount})`;
                default:
                    businessType = "未知";
                    break;
            }
            exportCSVData.push({
                username: `${audit.user.fullname}(${audit.user.email})`,
                packageName: (_b = audit.labelPackage) === null || _b === void 0 ? void 0 : _b.name,
                packageStatus: transformLabelPackageStatus(audit.labelPackage),
                taskName: (_c = audit.labelTask) === null || _c === void 0 ? void 0 : _c.name,
                businessName: ((_d = audit.labelTaskBusiness) === null || _d === void 0 ? void 0 : _d.name) || "未分配类型",
                businessType: businessType,
                detectionCount: detectionCount,
                classificationCount: classificationCount,
                segmentCount,
                segment3DCount,
                objectCount,
                trailCount,
                chunkCount,
                duration
            });
        });
    }
    get transformedAnnotation() {
        const annotationAuditsMap = new Map();
        this.status.userAudits.forEach(item => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5;
            if (annotationAuditsMap.has((_a = item.user) === null || _a === void 0 ? void 0 : _a.id)) {
                if (((_b = item.labelTaskBusiness) === null || _b === void 0 ? void 0 : _b.type) === LabelTaskBusiness.Type.Box) {
                    annotationAuditsMap.get((_c = item.user) === null || _c === void 0 ? void 0 : _c.id).totalDetectionCount += item.detectionCount;
                }
                if (((_d = item.labelTaskBusiness) === null || _d === void 0 ? void 0 : _d.type) === LabelTaskBusiness.Type.Piece) {
                    annotationAuditsMap.get((_e = item.user) === null || _e === void 0 ? void 0 : _e.id).totalClassificationCount += item.classificationCount;
                }
                if (((_f = item.labelTaskBusiness) === null || _f === void 0 ? void 0 : _f.type) === LabelTaskBusiness.Type.MaskBox) {
                    annotationAuditsMap.get((_g = item.user) === null || _g === void 0 ? void 0 : _g.id).totalSegmentCount += item.segmentCount;
                }
                if (((_h = item.labelTaskBusiness) === null || _h === void 0 ? void 0 : _h.type) === LabelTaskBusiness.Type.Cloud) {
                    annotationAuditsMap.get((_j = item.user) === null || _j === void 0 ? void 0 : _j.id).totalSegment3dCount += item.segment3dCount;
                    annotationAuditsMap.get((_k = item.user) === null || _k === void 0 ? void 0 : _k.id).totalInheritedSegment3dCount += item.inheritedSegment3dCount;
                }
                if (((_l = item.labelTaskBusiness) === null || _l === void 0 ? void 0 : _l.type) === LabelTaskBusiness.Type.Object) {
                    annotationAuditsMap.get((_m = item.user) === null || _m === void 0 ? void 0 : _m.id).totalObjectCount += item.objectCount;
                    annotationAuditsMap.get((_o = item.user) === null || _o === void 0 ? void 0 : _o.id).totalTrailCount += item.trailCount;
                }
                if (((_p = item.labelTaskBusiness) === null || _p === void 0 ? void 0 : _p.type) === LabelTaskBusiness.Type.Anchor) {
                    annotationAuditsMap.get((_q = item.user) === null || _q === void 0 ? void 0 : _q.id).totalObjectCount += item.anchorCount;
                    annotationAuditsMap.get((_r = item.user) === null || _r === void 0 ? void 0 : _r.id).totalTrailCount += item.anchorTrailCount;
                }
                if (((_s = item.labelTaskBusiness) === null || _s === void 0 ? void 0 : _s.type) === LabelTaskBusiness.Type.Chunk) {
                    annotationAuditsMap.get((_t = item.user) === null || _t === void 0 ? void 0 : _t.id).totalChunkCount += item.chunkCount;
                    annotationAuditsMap.get((_u = item.user) === null || _u === void 0 ? void 0 : _u.id).totalDuration += item.duration;
                }
            }
            else {
                let auditMapValue = {
                    userId: (_v = item.user) === null || _v === void 0 ? void 0 : _v.id,
                    email: (_w = item.user) === null || _w === void 0 ? void 0 : _w.email,
                    fullname: (_x = item.user) === null || _x === void 0 ? void 0 : _x.fullname,
                    totalDetectionCount: 0,
                    totalClassificationCount: 0,
                    totalSegmentCount: 0,
                    totalSegment3dCount: 0,
                    totalInheritedSegment3dCount: 0,
                    totalObjectCount: 0,
                    totalTrailCount: 0,
                    totalChunkCount: 0,
                    totalDuration: 0,
                    resourceMime: item.resourceMime
                };
                if (((_y = item.labelTaskBusiness) === null || _y === void 0 ? void 0 : _y.type) === LabelTaskBusiness.Type.Box) {
                    auditMapValue.totalDetectionCount = item.detectionCount;
                }
                if (((_z = item.labelTaskBusiness) === null || _z === void 0 ? void 0 : _z.type) === LabelTaskBusiness.Type.Piece) {
                    auditMapValue.totalClassificationCount = item.classificationCount;
                }
                if (((_0 = item.labelTaskBusiness) === null || _0 === void 0 ? void 0 : _0.type) === LabelTaskBusiness.Type.MaskBox) {
                    auditMapValue.totalSegmentCount = item.segmentCount;
                }
                if (((_1 = item.labelTaskBusiness) === null || _1 === void 0 ? void 0 : _1.type) === LabelTaskBusiness.Type.Cloud) {
                    auditMapValue.totalSegment3dCount = item.segment3dCount;
                    auditMapValue.totalInheritedSegment3dCount = item.inheritedSegment3dCount;
                }
                if (((_2 = item.labelTaskBusiness) === null || _2 === void 0 ? void 0 : _2.type) === LabelTaskBusiness.Type.Object) {
                    auditMapValue.totalObjectCount = item.objectCount;
                    auditMapValue.totalTrailCount = item.trailCount;
                }
                if (((_3 = item.labelTaskBusiness) === null || _3 === void 0 ? void 0 : _3.type) === LabelTaskBusiness.Type.Anchor) {
                    auditMapValue.totalObjectCount = item.anchorCount;
                    auditMapValue.totalTrailCount = item.anchorTrailCount;
                }
                if (((_4 = item.labelTaskBusiness) === null || _4 === void 0 ? void 0 : _4.type) === LabelTaskBusiness.Type.Chunk) {
                    auditMapValue.totalChunkCount = item.chunkCount;
                    auditMapValue.totalDuration = item.duration;
                }
                annotationAuditsMap.set((_5 = item.user) === null || _5 === void 0 ? void 0 : _5.id, auditMapValue);
            }
        });
        return [...annotationAuditsMap.values()];
    }
    get isUnableDownload() {
        return _.isEmpty(toJS(this.status.userAudits));
    }
    get isUnableSearch() {
        return _.isNil(toJS(this.status.calendarDate)) || this.status.loading;
    }
    get auditDateString() {
        const { calendarDate, auditPeriod } = this.status;
        let auditDateString = undefined;
        switch (auditPeriod) {
            case IAnnotationAudit.AuditPeriod.Daily:
                auditDateString = calendarDate.format("YYYY-MM-DD");
                break;
            case IAnnotationAudit.AuditPeriod.Weekly:
                auditDateString = calendarDate.endOf("week").format("YYYY-MM-DD");
                break;
            case IAnnotationAudit.AuditPeriod.Monthly:
                auditDateString = calendarDate.endOf("month").format("YYYY-MM-DD");
                break;
            default:
                return;
        }
        return auditDateString;
    }
    get filterUsers() {
        const { users, curGroupIds } = toJS(this.status);
        return users;
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], UserAuditStore.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], UserAuditStore.prototype, "resetStatus", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], UserAuditStore.prototype, "transformedAnnotation", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], UserAuditStore.prototype, "isUnableDownload", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], UserAuditStore.prototype, "isUnableSearch", null);
__decorate([
    computed,
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], UserAuditStore.prototype, "auditDateString", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], UserAuditStore.prototype, "filterUsers", null);
export const userAuditStore = new UserAuditStore();

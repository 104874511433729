var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import moment from "moment";
import { CSVLink } from "react-csv";
import { Select, Table, DatePicker, Button } from "antd";
import { taskAuditStore } from "./TaskAuditStore";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { LabelTaskBusiness } from "definition/entity/label-task-business";
import { transformLabelPackageStatus } from "util/status-transform";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
let TaskAudit = class TaskAudit extends React.Component {
    constructor() {
        super(...arguments);
        this.columns = [
            {
                title: "任务名称",
                dataIndex: "taskName",
                ellipsis: true,
                render: (value, record) => record === null || record === void 0 ? void 0 : record.taskName
            },
            {
                title: "计价类型",
                ellipsis: true,
                render: (value, record) => {
                    return (record === null || record === void 0 ? void 0 : record.businessName) ? record === null || record === void 0 ? void 0 : record.businessName : "未分配类型";
                }
            },
            {
                title: "计价单位",
                width: 160,
                render: (value, record) => {
                    switch (record === null || record === void 0 ? void 0 : record.businessType) {
                        case LabelTaskBusiness.Type.Box:
                            return <span>个（检测框）</span>;
                        case LabelTaskBusiness.Type.Piece:
                            return <span>张（图片）</span>;
                        case LabelTaskBusiness.Type.Object:
                            return <span>个（关键帧）</span>;
                        case LabelTaskBusiness.Type.Chunk:
                            return <span>段（分类内容）</span>;
                        case LabelTaskBusiness.Type.Anchor:
                            return <span>个（时空锚对象）</span>;
                        case LabelTaskBusiness.Type.MaskBox:
                            return <span>个（分割框）</span>;
                        case LabelTaskBusiness.Type.Cloud:
                            return <span>个（点云）</span>;
                        default:
                            return <span>未知</span>;
                    }
                }
            },
            {
                title: "检测总量",
                align: "center",
                width: 120,
                render: (value, record) => {
                    if ((record === null || record === void 0 ? void 0 : record.businessType) === LabelTaskBusiness.Type.Box) {
                        return <span>{record === null || record === void 0 ? void 0 : record.totalDetectionCount} (框)</span>;
                    }
                    return "-";
                }
            },
            {
                title: "属性总量",
                align: "center",
                width: 120,
                render: (value, record) => {
                    if ((record === null || record === void 0 ? void 0 : record.businessType) === LabelTaskBusiness.Type.Piece) {
                        return <span>{record === null || record === void 0 ? void 0 : record.totalClassificationCount} (张)</span>;
                    }
                    return "-";
                }
            },
            {
                title: "图像分割总量",
                align: "center",
                width: 120,
                render: (value, record) => {
                    return `${record === null || record === void 0 ? void 0 : record.totalSegmentCount} (框)`;
                }
            },
            {
                title: "点云分割总量（非复用+复用）",
                align: "center",
                width: 240,
                render: (value, record) => record &&
                    `${record.totalSegment3dCount}(${record.totalSegment3dCount - record.totalInheritedSegment3dCount} + ${record.totalInheritedSegment3dCount}) (个)`
            },
            {
                title: "关键帧总数",
                align: "center",
                width: 120,
                render: (value, record) => {
                    return `${record === null || record === void 0 ? void 0 : record.totalTrailCount} (个)`;
                }
            },
            {
                title: "对象总数",
                align: "center",
                width: 120,
                render: (value, record) => {
                    return `${record === null || record === void 0 ? void 0 : record.totalObjectCount} (个)`;
                }
            },
            {
                title: "分类内容总数",
                align: "center",
                width: 120,
                render: (value, record) => {
                    return `${record === null || record === void 0 ? void 0 : record.totalChunkCount} (段)`;
                }
            },
            {
                title: "总时长(s)",
                align: "center",
                width: 120,
                render: (value, record) => {
                    return record === null || record === void 0 ? void 0 : record.totalDuration.toFixed(2);
                }
            }
        ];
        this.childColumns = [
            {
                title: "包名",
                dataIndex: "id",
                width: 160,
                ellipsis: true,
                render: (value, record) => { var _a; return (_a = record === null || record === void 0 ? void 0 : record.labelPackage) === null || _a === void 0 ? void 0 : _a.name; }
            },
            {
                title: "状态",
                width: 160,
                render: (value, record) => {
                    return transformLabelPackageStatus(record.labelPackage);
                }
            },
            {
                title: "标注人员",
                width: 160,
                ellipsis: true,
                render: (value, record) => { var _a, _b; return `${(_a = record === null || record === void 0 ? void 0 : record.user) === null || _a === void 0 ? void 0 : _a.fullname}(${(_b = record === null || record === void 0 ? void 0 : record.user) === null || _b === void 0 ? void 0 : _b.email})`; }
            },
            {
                title: "检测量",
                align: "center",
                width: 120,
                render: (value, record) => {
                    var _a;
                    if (((_a = record === null || record === void 0 ? void 0 : record.labelTaskBusiness) === null || _a === void 0 ? void 0 : _a.type) === LabelTaskBusiness.Type.Box) {
                        return <span>{record === null || record === void 0 ? void 0 : record.detectionCount} (框)</span>;
                    }
                    return "-";
                }
            },
            {
                title: "属性量",
                align: "center",
                width: 120,
                render: (value, record) => {
                    var _a;
                    if (((_a = record === null || record === void 0 ? void 0 : record.labelTaskBusiness) === null || _a === void 0 ? void 0 : _a.type) === LabelTaskBusiness.Type.Piece) {
                        return <span>{record === null || record === void 0 ? void 0 : record.classificationCount} (张)</span>;
                    }
                    return "-";
                }
            },
            {
                title: "图像分割量",
                align: "center",
                width: 120,
                render: (value, record) => {
                    return record === null || record === void 0 ? void 0 : record.segmentCount;
                }
            },
            {
                title: "点云分割总量（非复用+复用）",
                align: "center",
                width: 240,
                render: (value, record) => record &&
                    `${record.segment3dCount}(${record.segment3dCount - record.inheritedSegment3dCount} + ${record.inheritedSegment3dCount}) (个)`
            },
            {
                title: "关键帧数",
                align: "center",
                width: 120,
                render: (value, record) => {
                    var _a, _b;
                    if (((_a = record === null || record === void 0 ? void 0 : record.labelTaskBusiness) === null || _a === void 0 ? void 0 : _a.type) === LabelTaskBusiness.Type.Object) {
                        return <span>{record === null || record === void 0 ? void 0 : record.trailCount}</span>;
                    }
                    else if (((_b = record === null || record === void 0 ? void 0 : record.labelTaskBusiness) === null || _b === void 0 ? void 0 : _b.type) === LabelTaskBusiness.Type.Anchor) {
                        return <span>{record === null || record === void 0 ? void 0 : record.anchorTrailCount}</span>;
                    }
                    else {
                        return 0;
                    }
                }
            },
            {
                title: "对象数",
                align: "center",
                width: 120,
                render: (value, record) => {
                    var _a, _b;
                    if (((_a = record === null || record === void 0 ? void 0 : record.labelTaskBusiness) === null || _a === void 0 ? void 0 : _a.type) === LabelTaskBusiness.Type.Object) {
                        return <span>{record === null || record === void 0 ? void 0 : record.objectCount}</span>;
                    }
                    else if (((_b = record === null || record === void 0 ? void 0 : record.labelTaskBusiness) === null || _b === void 0 ? void 0 : _b.type) === LabelTaskBusiness.Type.Anchor) {
                        return <span>{record === null || record === void 0 ? void 0 : record.anchorCount}</span>;
                    }
                    else {
                        return 0;
                    }
                }
            },
            {
                title: "分类内容数",
                align: "center",
                width: 120,
                render: (value, record) => {
                    return record === null || record === void 0 ? void 0 : record.chunkCount;
                }
            },
            {
                title: "时长(s)",
                align: "center",
                width: 120,
                render: (value, record) => {
                    return record === null || record === void 0 ? void 0 : record.duration;
                }
            }
        ];
        this.CSVHeaders = [
            {
                label: "任务名称",
                key: "taskName"
            },
            {
                label: "计价类型",
                key: "businessName"
            },
            {
                label: "计价单位",
                key: "businessType"
            },
            {
                label: "包名",
                key: "packageName"
            },
            {
                label: "状态",
                key: "packageStatus"
            },
            {
                label: "标注人员",
                key: "labelUser"
            },
            {
                label: "检测量",
                key: "detectionCount"
            },
            {
                label: "属性量",
                key: "classificationCount"
            },
            {
                label: "图像分割量",
                key: "segmentCount"
            },
            {
                label: "点云分割量(非复用+复用)",
                key: "segment3DCount"
            },
            {
                label: "关键帧数",
                key: "trailCount"
            },
            {
                label: "对象数",
                key: "objectCount"
            },
            {
                label: "分类内容数",
                key: "chunkCount"
            },
            {
                label: "时长(s)",
                key: "duration"
            }
        ];
        this.expandedRowRender = (record) => {
            const status = taskAuditStore.status;
            const childData = status.taskAudits.filter(item => { var _a; return record.taskId === ((_a = item.labelTask) === null || _a === void 0 ? void 0 : _a.id); });
            return (<Table rowKey={record => `detail${record.id}`} columns={this.childColumns} dataSource={childData} pagination={false}/>);
        };
    }
    componentDidMount() {
        taskAuditStore.loadBusinesses();
        taskAuditStore.loadTasks();
        taskAuditStore.loadData();
    }
    render() {
        const status = taskAuditStore.status;
        return (<div>
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        }}>
          <div>
            <DatePicker style={{
            width: 240
        }} placeholder="选择日" disabledDate={current => {
            return (current &&
                current >=
                    moment()
                        .startOf("day")
                        .add(1, "day"));
        }} value={status.calendarDate} onChange={date => {
            status.calendarDate = date;
        }}/>
            <Select showSearch allowClear mode="multiple" placeholder="请选择计价类型" style={{ width: 240, marginLeft: 10 }} value={toJS(status.curBusinessIds)} onChange={value => {
            status.curBusinessIds = value;
            status.curTaskIds = [];
        }} filterOption={(input, option) => {
            if (option && option.props && option.props.title) {
                return option.props.title === input || option.props.title.indexOf(input) !== -1;
            }
            else {
                return true;
            }
        }}>
              {status.businesses.map(business => (<Select.Option key={business.id} title={business.name} value={business.id}>
                  {business.name}
                </Select.Option>))}
            </Select>
            <Select placeholder="请选择任务" allowClear showSearch mode="multiple" value={toJS(status.curTaskIds)} style={{ width: 300, marginLeft: 10 }} onChange={value => {
            status.curTaskIds = value;
        }} filterOption={(input, option) => {
            if (option && option.props && option.props.title) {
                return option.props.title === input || option.props.title.indexOf(input) !== -1;
            }
            else {
                return true;
            }
        }}>
              {toJS(taskAuditStore.filterTasks).map(task => (<Select.Option key={task.id} title={task.name} value={task.id}>
                  {task.name}
                </Select.Option>))}
            </Select>
            <Button style={{
            marginLeft: 10
        }} icon={<SearchOutlined />} disabled={taskAuditStore.isUnableSearch} onClick={() => {
            taskAuditStore.loadData();
        }}></Button>
          </div>

          <CSVLink data={toJS(status.exportCSVData)} filename={status.CSVFilename} headers={this.CSVHeaders}>
            <Button type="primary" shape="round" icon={<DownloadOutlined />} disabled={taskAuditStore.isUnableDownload} onClick={() => taskAuditStore.downAnnotationAuditFile()}/>
          </CSVLink>
        </div>

        <Table loading={status.loading} scroll={{ x: true }} rowKey={record => `workload${record.taskId}`} bordered={true} style={{ marginTop: "20px" }} dataSource={toJS(taskAuditStore.transformedAnnotation)} columns={this.columns} expandedRowRender={this.expandedRowRender}/>
      </div>);
    }
};
TaskAudit = __decorate([
    observer
], TaskAudit);
export { TaskAudit };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button, Card, Checkbox, Collapse, Drawer, Input, message, Popover, Radio, Switch, Tag, Row, Select, Modal, InputNumber, Divider } from "antd";
import { PictureOutlined, PlayCircleOutlined, BarsOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import classNames from "classnames";
import Color from "color";
import { IResource } from "definition/entity/resource";
import * as _ from "lodash";
import { extendObservable } from "mobx";
import { observer } from "mobx-react";
import videoLabelStore from "page/label/video/VideoLabel/VideoLabelStore";
import * as React from "react";
import { EventBlock } from "../../../../../../component/EventBlock/EventBlock";
import { IconFont } from "../../../../../../component/IconFont/IconFont";
import { AnchorTool, ClassificationTool, DetectionTool } from "../../../../../../definition/entity/label-task";
import { imageLabelStore } from "../../ImageLabelStore";
import { PainterDom } from "../../provider/painter-dom";
import "./LabelDisplay.less";
import { basename, extname } from "path";
import * as THREE from "three";
import { toJS } from "mobx";
import { kdTree } from "../../../../../../util/kdtree.js";
let MultiLayerElementDisPlay = class MultiLayerElementDisPlay extends React.Component {
    render() {
        var _a;
        const { ele } = this.props;
        return (<Card id={ele.id} key={ele.id} size="small" className="layer-element-card" style={Object.assign({}, imageLabelStore.status.selectedElementIds.includes(ele.id) && {
            backgroundColor: Color(ele.label.color).fade(0.75)
        })} title={<div className="head-title-inner" onClick={() => {
            imageLabelStore.status.selectedElementIds = [ele.id];
        }}>
            <div title={`${ele.label.locale || ele.label.key} (${ele.id})`} className="label-id">
              {ele.label.locale || ele.label.key}&nbsp;({ele.id})
            </div>
            <div>
              {imageLabelStore.status.curResource.mime === IResource.Mime.Video && (<Popover placement="bottomRight" title="历史帧" content={<div className="popover-content">
                      {ele.detection.tool === DetectionTool.Chunk && (<React.Fragment>
                          <div>
                            <Button onClick={() => {
            videoLabelStore.currentFrame = ele.detection.value.startFrame;
        }} size="small" type="link">
                              {Math.round(ele.detection.value.startFrame)}帧-{Math.floor(ele.detection.value.startTime)}
                              ms
                            </Button>
                            <Tag>首帧</Tag>
                          </div>
                          <div>
                            <Button onClick={() => {
            videoLabelStore.currentFrame = ele.detection.value.endFrame;
        }} size="small" type="link">
                              {Math.round(ele.detection.value.endFrame)}帧-{Math.floor(ele.detection.value.endTime)}ms
                            </Button>
                            <Tag>尾帧</Tag>
                          </div>
                        </React.Fragment>)}
                      {(_a = ele.detection.trails) === null || _a === void 0 ? void 0 : _a.map((item, index) => (<div key={index}>
                          <Button onClick={() => {
            videoLabelStore.currentFrame = item.frame;
        }} size="small" type="link">
                            {Math.round(item.frame)}帧-{Math.floor(item.frame * videoLabelStore.meta.timePerFrame)}
                            ms
                          </Button>
                          <Button onClick={() => {
            ele.detection.trails.splice(index, 1);
        }} disabled={ele.detection.trails.length === 1}>
                            删除
                          </Button>
                        </div>))}
                    </div>}>
                  <PictureOutlined />
                </Popover>)}
              {ele.label.tool === DetectionTool.Chunk && (<PlayCircleOutlined style={{ marginLeft: "6px" }} onClick={() => {
            videoLabelStore.play(ele.detection.value.startTime, ele.detection.value.endTime);
        }}/>)}
              <IconFont style={{ marginLeft: "6px" }} type={ele.visible ? "icon-eye-open" : "icon-eye-off"} onClick={e => {
            ele.visible = !ele.visible;
            e.stopPropagation();
        }}/>
            </div>
          </div>}>
        {ele.detection.tool === DetectionTool.Chunk && (<Row justify="space-between">
            <span>
              首帧:{" "}
              <InputNumber min={0} max={ele.detection.value.endFrame || videoLabelStore.totalFrames} value={ele.detection.value.startFrame} onChange={v => {
            v = v || 0;
            if (v < ele.detection.value.endFrame) {
                ele.detection.value.startFrame = v;
                ele.detection.value.startTime = v * videoLabelStore.meta.timePerFrame;
                videoLabelStore.currentFrame = v;
            }
        }} size="small" step={1} precision={0}></InputNumber>
            </span>
            <span>
              尾帧:{" "}
              <InputNumber min={ele.detection.value.startFrame || 0} max={videoLabelStore.totalFrames} value={ele.detection.value.endFrame} onChange={v => {
            v = v || 0;
            if (v > ele.detection.value.startFrame) {
                ele.detection.value.endFrame = v;
                ele.detection.value.endTime = v * videoLabelStore.meta.timePerFrame;
                videoLabelStore.currentFrame = v;
            }
        }} size="small" step={1} precision={0}></InputNumber>
            </span>
          </Row>)}
        {imageLabelStore.status.mime == IResource.Mime.Video && ele.label.tool !== DetectionTool.Chunk && (<Options ele={ele}/>)}
        {ele.label.type === "detection" &&
            ele.label.classifications.map((classification, index) => {
                var _a, _b;
                let matchRes = _.find(ele.detection.classifications, item => item.key === classification.key);
                let content = null;
                switch (classification.tool) {
                    case ClassificationTool.Switch: {
                        content = (<div key={classification.key} className="classification-switch">
                    <div className="switch-header">{classification.locale || classification.key}</div>
                    <Switch style={{ marginLeft: "8px" }} size="small" checked={matchRes.value} onChange={checked => {
                            matchRes.value = checked;
                            imageLabelStore.setStatus({
                                layerElements: imageLabelStore.status.layerElements
                            });
                        }}/>
                  </div>);
                        break;
                    }
                    case ClassificationTool.Radio: {
                        content = (<div key={classification.key} className="classification-radio">
                    <div className="radio-header">{classification.locale || classification.key}</div>
                    <Radio.Group className="radio-group" value={matchRes.value} onChange={event => {
                            matchRes.value = event.target.value;
                            imageLabelStore.setStatus({
                                layerElements: imageLabelStore.status.layerElements
                            });
                        }}>
                      {(_a = classification.options) === null || _a === void 0 ? void 0 : _a.map(option => (<Radio className="radio-option" key={option.key} value={option.key}>
                          {option.locale || option.key}
                        </Radio>))}
                    </Radio.Group>
                  </div>);
                        break;
                    }
                    case ClassificationTool.Checkbox: {
                        content = (<div key={classification.key} className="classification-checkbox">
                    <div className="checkbox-header">{classification.locale || classification.key}</div>
                    <Checkbox.Group className="checkbox-group" value={matchRes.value} onChange={checkedValue => {
                            matchRes.value = checkedValue;
                            imageLabelStore.setStatus({
                                layerElements: imageLabelStore.status.layerElements
                            });
                        }}>
                      {(_b = classification.options) === null || _b === void 0 ? void 0 : _b.map(option => (<Checkbox className="checkbox-option" key={option.key} value={option.key}>
                          {option.locale || option.key}
                        </Checkbox>))}
                    </Checkbox.Group>
                  </div>);
                        break;
                    }
                    case ClassificationTool.Text: {
                        content = (<div key={classification.key} className="classification-text">
                    <div className="text-header">{classification.locale || classification.key}</div>
                    <EventBlock blockEvent="keydown">
                      <Input value={matchRes.value} size="small" onChange={event => {
                            matchRes.value = event.target.value;
                            imageLabelStore.setStatus({
                                layerElements: imageLabelStore.status.layerElements
                            });
                        }}/>
                    </EventBlock>
                  </div>);
                        break;
                    }
                    default:
                        return;
                }
                return (<React.Fragment key={index}>
                {index !== 0 && <Divider style={{ margin: "2px 0" }}/>}
                {content}
              </React.Fragment>);
            })}
      </Card>);
    }
};
MultiLayerElementDisPlay = __decorate([
    observer
], MultiLayerElementDisPlay);
let ZeroLayerElementDisplay = class ZeroLayerElementDisplay extends React.Component {
    render() {
        var _a;
        const { ele } = this.props;
        return (<React.Fragment>
        <div id={ele.id} className="layer-element-item" key={ele.id} style={Object.assign({}, imageLabelStore.status.selectedElementIds.includes(ele.id) && {
            backgroundColor: Color(ele.label.color).fade(0.75)
        })} onClick={() => {
            imageLabelStore.status.selectedElementIds = [ele.id];
        }}>
          <div className="extra">
            {imageLabelStore.status.curResource.mime === IResource.Mime.Video && (<Popover placement="bottomRight" title="历史帧" content={<div className="popover-content">
                    {ele.detection.tool === DetectionTool.Chunk && (<React.Fragment>
                        <div>
                          <Button onClick={() => {
            videoLabelStore.currentFrame = ele.detection.value.startFrame;
        }} size="small" type="link">
                            {Math.round(ele.detection.value.startFrame)}帧-{Math.floor(ele.detection.value.startTime)}ms
                          </Button>
                          <Tag>首帧</Tag>
                        </div>
                        <div>
                          <Button onClick={() => {
            videoLabelStore.currentFrame = ele.detection.value.endFrame;
        }} size="small" type="link">
                            {Math.round(ele.detection.value.endFrame)}帧-{Math.floor(ele.detection.value.endTime)}ms
                          </Button>
                          <Tag>尾帧</Tag>
                        </div>
                      </React.Fragment>)}
                    {ele.detection.tool !== DetectionTool.Chunk && ((_a = ele.detection.trails) === null || _a === void 0 ? void 0 : _a.map((item, index) => (<div key={index}>
                          <Button onClick={() => {
            videoLabelStore.currentFrame = item.frame;
        }} size="small" type="link">
                            {Math.round(item.frame)}帧-{Math.floor(item.frame * videoLabelStore.meta.timePerFrame)}ms
                          </Button>
                          <Button onClick={() => {
            ele.detection.trails.splice(index, 1);
        }} disabled={ele.detection.trails.length === 1}>
                            删除
                          </Button>
                        </div>)))}
                  </div>}>
                <PictureOutlined />
              </Popover>)}
            {ele.label.tool === DetectionTool.Chunk && (<PlayCircleOutlined onClick={() => {
            videoLabelStore.play(ele.detection.value.startTime, ele.detection.value.endTime);
        }}/>)}
            <IconFont type={ele.visible ? "icon-eye-open" : "icon-eye-off"} onClick={e => {
            ele.visible = !ele.visible;
            e.stopPropagation();
        }}/>
            {imageLabelStore.status.mime == IResource.Mime.Video && ele.label.tool !== DetectionTool.Chunk && (<Options ele={ele}/>)}
          </div>
          <div title={ele.id} className="label-id">
            {ele.id}
          </div>
          {ele.label.tool === DetectionTool.Chunk && (<Row justify="space-between">
              <span>
                首帧:{" "}
                <InputNumber min={0} max={ele.detection.value.endFrame || videoLabelStore.totalFrames} value={ele.detection.value.startFrame} onChange={v => {
            v = v || 0;
            if (v < ele.detection.value.endFrame) {
                ele.detection.value.startFrame = v;
                ele.detection.value.startTime = v * videoLabelStore.meta.timePerFrame;
                videoLabelStore.currentFrame = v;
            }
        }} size="small" step={1} precision={0}></InputNumber>
              </span>
              <span>
                尾帧:{" "}
                <InputNumber min={ele.detection.value.startFrame || 0} max={videoLabelStore.totalFrames} value={ele.detection.value.endFrame} onChange={v => {
            v = v || 0;
            if (v > ele.detection.value.startFrame) {
                ele.detection.value.endFrame = v;
                ele.detection.value.endTime = v * videoLabelStore.meta.timePerFrame;
                videoLabelStore.currentFrame = v;
            }
        }} size="small" step={1} precision={0}></InputNumber>
              </span>
            </Row>)}
        </div>
      </React.Fragment>);
    }
};
ZeroLayerElementDisplay = __decorate([
    observer
], ZeroLayerElementDisplay);
let Options = class Options extends React.Component {
    get currentTrail() {
        const trails = this.props.ele.detection.trails || [];
        return trails.filter(v => v.frame === imageLabelStore.currentFrame)[0];
    }
    get targetTrail() {
        if (this.currentTrail) {
            return this.currentTrail;
        }
        const trails = this.props.ele.detection.trails || [];
        const frame = videoLabelStore.currentFrame;
        const tweenTrail = videoLabelStore.getTweenTrail(trails, this.props.ele.detection.tool, frame);
        if (this.props.ele.detection.tool === DetectionTool.Rectangle) {
            const _a = tweenTrail, { x, y, width, height } = _a, rest = __rest(_a, ["x", "y", "width", "height"]);
            return Object.assign(Object.assign({}, rest), this.getRealAttrVal({ x, y, width, height }));
        }
        else if (this.props.ele.detection.tool === DetectionTool.Dot) {
            const _b = tweenTrail, { cx, cy } = _b, rest = __rest(_b, ["cx", "cy"]);
            return Object.assign(Object.assign({}, rest), this.getRealAttrVal({ cx, cy }));
        }
    }
    getRealAttrVal(attributes) {
        let ret = {};
        Object.keys(attributes).forEach(k => {
            ret[k] = attributes[k] / imageLabelStore.status.zoomRatio;
        });
        return ret;
    }
    onChange(e, field) {
        let checked = e.target.checked;
        let currentTrail = null;
        if (this.currentTrail) {
            currentTrail = this.currentTrail;
        }
        else {
            this.props.ele.detection.trails.push(Object.assign({}, this.targetTrail));
            currentTrail = this.props.ele.detection.trails[this.props.ele.detection.trails.length - 1];
            this.props.ele.detection.trails = this.props.ele.detection.trails.sort((a, b) => a.frame - b.frame);
        }
        if (field === "inview") {
            currentTrail.inview = !checked;
        }
        else if (field === "cover") {
            currentTrail.cover = checked;
        }
        else if (field === "hidden") {
            extendObservable(currentTrail, {
                hidden: checked
            });
            currentTrail.hidden = checked;
            this.setState({});
        }
        videoLabelStore.videoStage.drawLabel();
    }
    render() {
        var _a, _b, _c;
        const { style } = this.props;
        return (<div className="options" style={style}>
        <Checkbox onChange={e => this.onChange(e, "inview")} checked={!((_a = this.targetTrail) === null || _a === void 0 ? void 0 : _a.inview)}>
          视图外
        </Checkbox>
        <Checkbox onChange={e => this.onChange(e, "cover")} checked={(_b = this.targetTrail) === null || _b === void 0 ? void 0 : _b.cover}>
          被遮挡
        </Checkbox>
        <Checkbox onChange={e => this.onChange(e, "hidden")} checked={(_c = this.targetTrail) === null || _c === void 0 ? void 0 : _c.hidden}>
          视图中消失
        </Checkbox>
      </div>);
    }
};
Options = __decorate([
    observer
], Options);
let LabelDisplay = class LabelDisplay extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            isPreviewVisible: false,
            annotation: {}
        };
        this.multiClassificationPanel = (label, matchLayerElements) => {
            return (<Collapse.Panel className="multi-classification-panel" key={label.key} header={this.batchAction(label, matchLayerElements)}>
        <div className="layer-elements-wrapper">
          {matchLayerElements.map(ele => (<MultiLayerElementDisPlay key={ele.id} ele={ele}/>))}
        </div>
      </Collapse.Panel>);
        };
        this.zeroClassificationPanel = (label, matchLayerElements) => {
            return (<Collapse.Panel className="zero-classification-panel" key={label.key} header={this.batchAction(label, matchLayerElements)}>
        <div className="layer-elements-wrapper">
          {matchLayerElements.map(ele => (<ZeroLayerElementDisplay key={ele.id} ele={ele}/>))}
        </div>
      </Collapse.Panel>);
        };
        this.batchAction = (label, matchLayerElements) => {
            const status = imageLabelStore.status;
            return (<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden"
            }}>{`${label.locale || label.key} (${matchLayerElements.length})`}</div>
        <div onClick={e => e.stopPropagation()}>
          <Popover placement="bottomRight" title="批量操作" content={<div style={{ display: "grid", gridTemplateColumns: "1fr", gridRowGap: "4px" }}>
                <Button onClick={() => {
                matchLayerElements.forEach(ele => (ele.visible = false));
                imageLabelStore.painterWrapperRef.rerender();
            }}>
                  隐藏
                </Button>
                <Button onClick={() => {
                matchLayerElements.forEach(ele => (ele.visible = true));
                imageLabelStore.painterWrapperRef.rerender();
            }}>
                  显示
                </Button>
                <Button key="delete" onClick={() => {
                _.remove(status.layerElements, ele => matchLayerElements.map(i => i.id).includes(ele.id));
                imageLabelStore.painterWrapperRef.rerender();
            }}>
                  删除
                </Button>
              </div>}>
            <BarsOutlined />
          </Popover>
        </div>
      </div>);
        };
    }
    get groupedAnchorElementsByAncestorId() {
        return _.groupBy(imageLabelStore.anchorElements, ele => ele.id
            .split(/_/g)
            .slice(0, -1)
            .join("_"));
    }
    deleteAncestorAnchor(ancestorId) {
        _.remove(imageLabelStore.status.layerElements, ele => {
            return ele.id.startsWith(ancestorId);
        });
    }
    deleteAnchor(ele, ancestorId) {
        const index = _.findIndex(imageLabelStore.status.layerElements, { id: ele.id });
        if (index > -1) {
            imageLabelStore.status.layerElements.splice(index, 1);
            this.correctKeyFrame(ancestorId);
        }
    }
    addAnchor(ancestorId, matchLabel, group) {
        const { zoomRatio } = imageLabelStore.status;
        const id = PainterDom.generateId(ancestorId);
        const trail = this.findLastEleKeyFrame(group, 1);
        if (trail) {
            const width = imageLabelStore.status.config.painterBGSize.width * zoomRatio;
            let direction = "r";
            let gap = 20;
            let cx = trail.cx * zoomRatio;
            let cy = trail.cy * zoomRatio;
            if (cx + gap > width) {
                direction = "l";
            }
            if (direction === "l") {
                cx = cx - gap;
            }
            else if (direction === "r") {
                cx = cx + gap;
            }
            imageLabelStore.status.layerElements.push({
                id,
                label: matchLabel,
                visible: true,
                attributes: {
                    cx,
                    cy
                },
                detection: {
                    key: matchLabel.key,
                    value: null,
                    tool: AnchorTool.Dot,
                    trails: [
                        {
                            cx: cx / zoomRatio,
                            cy: cy / zoomRatio,
                            frame: trail.frame,
                            keyFrame: 0
                        }
                    ]
                },
                display: "normal"
            });
        }
    }
    correctKeyFrame(ancestorId) {
        const elements = this.groupedAnchorElementsByAncestorId[ancestorId];
        let lastHasEndFrameFrame = null;
        let modify = false;
        elements.forEach(ele => {
            ele.detection.trails.forEach(trail => {
                if (trail.keyFrame === 1) {
                    lastHasEndFrameFrame = trail.frame;
                }
                else if (trail.keyFrame === 0) {
                    if (lastHasEndFrameFrame != null && lastHasEndFrameFrame !== trail.frame) {
                        trail.frame = lastHasEndFrameFrame;
                        modify = true;
                    }
                }
            });
            if (modify) {
                ele.detection.trails = ele.detection.trails.sort((a, b) => a.frame - b.frame);
                modify = false;
            }
        });
    }
    findLastEleKeyFrame(elements, keyFrame) {
        const ele = _.last(elements);
        const trail = _.find(ele.detection.trails, { keyFrame });
        if (trail) {
            return trail;
        }
        return null;
    }
    onTagClick(trail, value, index, trails, idx, childs, ancestorId) {
        let headFrameIndex = _.findIndex(trails, { keyFrame: 0 });
        let tailFrameIndex = _.findIndex(trails, { keyFrame: 1 });
        let headFrameEle = trails[headFrameIndex];
        let tailFrameEle = trails[tailFrameIndex];
        if (tailFrameIndex < 0) {
            tailFrameIndex = Infinity;
        }
        if (value === 0) {
            if (index < tailFrameIndex && idx === 0) {
                trail.keyFrame = 0;
                headFrameEle && (headFrameEle.keyFrame = undefined);
            }
        }
        else if (value === 1) {
            const restChilds = childs.slice(idx + 1);
            for (let i = 0; i < restChilds.length; i++) {
                const _trails = restChilds[i].detection.trails || [];
                for (let j = 0; j < _trails.length; j++) {
                    if (_trails[j].keyFrame === 1 && trail.frame >= _trails[j].frame) {
                        return message.error("前一个锚点的尾帧不能大于后一个锚点的尾帧！");
                    }
                }
            }
            if (index > headFrameIndex) {
                tailFrameEle && (tailFrameEle.keyFrame = undefined);
                trail.keyFrame = 1;
            }
        }
        this.correctKeyFrame(ancestorId);
    }
    toggleElementsVisible(eles, visible) {
        eles.forEach(ele => (ele.visible = visible));
    }
    renderAnchor() {
        const anchorElements = imageLabelStore.anchorElements;
        const anchorKeys = _.uniq(anchorElements.map(item => item.label.key));
        return (<React.Fragment>
        <Collapse key={anchorKeys.length} defaultActiveKey={anchorKeys} expandIconPosition="end" className="label-display-anchor">
          {anchorKeys.map(groupKey => {
            const matchLabel = _.find(imageLabelStore.status.selectableLabel.anchors, item => item.key === groupKey);
            const matchLayerElements = anchorElements.filter(i => i.label.key === groupKey);
            const groupedMatchLayerElements = _.groupBy(matchLayerElements, ele => ele.id
                .split(/_/g)
                .slice(0, -1)
                .join("_"));
            const groupedMatchLayerElementKeys = Object.keys(groupedMatchLayerElements);
            return (<Collapse.Panel key={groupKey} header={<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <span>时空锚</span>
                    <div onClick={e => e.stopPropagation()}>
                      <Button style={{ marginRight: 5 }} onClick={() => {
                videoLabelStore.previewSyntheticImage();
            }} size="small">
                        预览
                      </Button>
                      <Popover placement="bottomRight" title="批量操作" content={<div style={{ display: "grid", gridTemplateColumns: "1fr", gridRowGap: "4px" }}>
                            <Button onClick={() => {
                anchorElements.forEach(ele => (ele.visible = false));
            }}>
                              隐藏
                            </Button>
                            <Button onClick={() => {
                anchorElements.forEach(ele => (ele.visible = true));
            }}>
                              显示
                            </Button>
                            <Button key="delete" onClick={() => {
                _.remove(imageLabelStore.status.layerElements, ele => matchLayerElements.map(i => i.id).includes(ele.id));
            }}>
                              删除
                            </Button>
                          </div>}>
                        <BarsOutlined />
                      </Popover>
                    </div>
                  </div>}>
                <div className="layer-elements-wrapper">
                  {Object.keys(groupedMatchLayerElements).map(ancestorId => {
                const childs = groupedMatchLayerElements[ancestorId];
                const isAllChildHidden = childs.every(el => el.visible == false);
                return (<Collapse style={{ marginBottom: 5 }} key={ancestorId} defaultActiveKey={groupedMatchLayerElementKeys} expandIconPosition="end">
                        <Collapse.Panel key={ancestorId} header={<div onClick={e => e.stopPropagation()} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                              <span>{`锚点：${ancestorId} (${childs.length})`}</span>
                              <div>
                                <Button onClick={() => {
                    videoLabelStore.previewSyntheticImage(ancestorId);
                }} style={{ marginRight: 5 }} size="small">
                                  预览
                                </Button>
                                <IconFont onClick={() => {
                    this.toggleElementsVisible(childs, isAllChildHidden);
                }} type={isAllChildHidden ? "icon-eye-off" : "icon-eye-open"}></IconFont>
                                <DeleteOutlined style={{ marginLeft: 5 }} onClick={() => this.deleteAncestorAnchor(ancestorId)}/>
                              </div>
                            </div>}>
                          {childs.map((ele, idx) => {
                    var _a;
                    return (<div key={idx} style={Object.assign({}, (imageLabelStore.status.selectedElementIds.includes(ele.id) && {
                        backgroundColor: Color(ele.label.color).fade(0.75)
                    }))} onClick={() => {
                        imageLabelStore.status.selectedElementIds = [ele.id];
                    }} className="layer-element-item">
                              {ele.id}
                              <div>
                                <IconFont style={{ marginRight: 5 }} onClick={e => {
                        this.toggleElementsVisible([ele], !ele.visible);
                    }} type={!ele.visible ? "icon-eye-off" : "icon-eye-open"}/>
                                <Popover placement="bottomRight" title="历史帧" content={<div className="popover-content">
                                      {(_a = ele.detection.trails) === null || _a === void 0 ? void 0 : _a.map((item, index) => (<div key={index}>
                                          <Button onClick={() => {
                        videoLabelStore.currentFrame = item.frame;
                    }} size="small" type="link">
                                            {Math.round(item.frame)}帧-
                                            {Math.floor(item.frame * videoLabelStore.meta.timePerFrame)}
                                            ms
                                          </Button>
                                          <Tag onClick={() => this.onTagClick(item, 0, index, ele.detection.trails, idx, childs, ancestorId)} className={classNames({ active: item.keyFrame === 0 })}>
                                            首帧
                                          </Tag>
                                          <Tag onClick={() => this.onTagClick(item, 1, index, ele.detection.trails, idx, childs, ancestorId)} className={classNames({ active: item.keyFrame === 1 })}>
                                            尾帧
                                          </Tag>
                                          <Button size="small" onClick={() => {
                        ele.detection.trails.splice(index, 1);
                    }} disabled={ele.detection.trails.length === 1}>
                                            删除
                                          </Button>
                                        </div>))}
                                    </div>}>
                                  <PictureOutlined />
                                </Popover>
                                <DeleteOutlined style={{ marginLeft: 5 }} onClick={() => {
                        this.deleteAnchor(ele, ancestorId);
                    }}/>
                              </div>
                            </div>);
                })}
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 5 }}>
                            <Button disabled={this.findLastEleKeyFrame(childs, 1) ? false : true} size="small" icon={<PlusOutlined />} onClick={() => {
                    this.addAnchor(ancestorId, matchLabel, childs);
                }}></Button>
                          </div>
                        </Collapse.Panel>
                      </Collapse>);
            })}
                </div>
              </Collapse.Panel>);
        })}
        </Collapse>
      </React.Fragment>);
    }
    renderSegment(segmentKeys, segmentElements) {
        return (<div>
        <Collapse key={segmentKeys.length} defaultActiveKey={segmentKeys} expandIconPosition="end" className="label-display-detection">
          {segmentKeys.map(groupKey => {
            const matchLabel = _.find(imageLabelStore.status.selectableLabel.segments, item => item.key === groupKey);
            const matchLayerElements = segmentElements.filter(i => i.label.key === groupKey);
            return (<Collapse.Panel className="zero-classification-panel" key={matchLabel.key} header={this.batchAction(matchLabel, matchLayerElements)}>
                <div className="layer-elements-wrapper">
                  {matchLayerElements.map((ele, idx) => {
                var _a;
                return (<div key={ele.id}>
                      <div id={ele.id} className="layer-element-item" style={Object.assign({}, imageLabelStore.status.selectedElementIds.includes(ele.id) && {
                    backgroundColor: Color(ele.label.color)
                        .fade(0.75)
                        .toString()
                })} onClick={() => {
                    imageLabelStore.status.selectedElementIds = [ele.id];
                    imageLabelStore.painterWrapperRef.rerender();
                }}>
                        <div className="extra">
                          
                          <IconFont type={ele.visible ? "icon-eye-open" : "icon-eye-off"} onClick={e => {
                    ele.visible = !ele.visible;
                    e.stopPropagation();
                    imageLabelStore.painterWrapperRef.rerender();
                }}/>
                        </div>
                        <div title={ele.id} className="label-id">
                          {ele.id}
                        </div>
                      </div>
                      <Row align="middle" justify="center" style={{ height: 40, borderBottom: "0.01rem solid #e8e8e8" }}>
                        <Radio.Group value={ele.detection.layer} onChange={e => (ele.detection.layer = e.target.value)}>
                          <Radio value={0}>
                            <svg style={{ verticalAlign: -5 }} viewBox="0 0 1024 1024" version="1.1" width="1.5em" height="1.5em">
                              <path d="M714.666667 394.666667L917.333333 277.333333 512 42.666667 106.666667 277.333333l202.666666 117.333334L106.666667 512l202.666666 117.333333L106.666667 746.666667 512 981.333333l405.333333-234.666666-202.666666-117.333334L917.333333 512z m-362.666667 24.746666L512 512l160-92.586667L832 512 512 697.173333 192 512zM832 746.666667L512 931.84 192 746.666667l160-92.586667L512 746.666667l160-92.586667z" fill="" p-id="22120"></path>
                            </svg>
                            顶层
                          </Radio>
                          <Radio value={1}>
                            <svg style={{ verticalAlign: -5 }} viewBox="0 0 1024 1024" version="1.1" width="1.5em" height="1.5em">
                              <path d="M746.965333 617.941333l26.325334-43.904 173.354666 104.021334L512 938.837333 77.354667 678.058667l168.405333-101.034667 26.325333 43.904-95.189333 57.130667 335.061333 201.045333 335.146667-201.045333z" fill="" p-id="30039"></path>
                              <path d="M746.965333 458.922667l26.325334-43.904 173.354666 104.021333L512 779.818667 77.354667 519.04 245.76 418.005333l26.325333 43.904L512 597.333333z" fill="" p-id="30040"></path>
                              <path d="M946.645333 358.656L512 97.877333 77.354667 358.656 512 619.434667l434.645333-260.778667zM512 157.568l335.104 201.088L512 559.744 176.853333 358.656 512 157.568z" fill="" p-id="30041"></path>
                            </svg>
                            中间层
                          </Radio>
                          <Radio value={2}>
                            <svg style={{ verticalAlign: -5 }} viewBox="0 0 1024 1024" version="1.1" width="1.5em" height="1.5em">
                              <path d="M917.333333 512l-202.666666-117.333333L917.333333 277.333333 512 42.666667 106.666667 277.333333l202.666666 117.333334L106.666667 512l202.666666 117.333333L106.666667 746.666667 512 981.333333l405.333333-234.666666-202.666666-117.333334zM512 92.16l320 185.173333-320 185.173334L192 277.333333zM352 419.413333L512 512l160-92.586667L832 512 512 697.173333 192 512z" fill="" p-id="21348"></path>
                            </svg>
                            底层
                          </Radio>
                        </Radio.Group>
                      </Row>
                      <div style={{ padding: "0 10px" }}>
                        {(_a = ele.label.classifications) === null || _a === void 0 ? void 0 : _a.map((classification, index) => {
                    var _a, _b;
                    let matchRes = _.find(ele.detection.classifications, item => item.key === classification.key);
                    let content = null;
                    switch (classification.tool) {
                        case ClassificationTool.Switch: {
                            content = (<div key={classification.key} className="classification-switch">
                                  <div className="switch-header">{classification.locale || classification.key}</div>
                                  <Switch style={{ marginLeft: "8px" }} size="small" checked={matchRes.value} onChange={checked => {
                                matchRes.value = checked;
                                imageLabelStore.setStatus({
                                    layerElements: imageLabelStore.status.layerElements
                                });
                            }}/>
                                </div>);
                            break;
                        }
                        case ClassificationTool.Radio: {
                            content = (<div key={classification.key} className="classification-radio">
                                  <div className="radio-header">{classification.locale || classification.key}</div>
                                  <Radio.Group className="radio-group" value={matchRes.value} onChange={event => {
                                matchRes.value = event.target.value;
                                imageLabelStore.setStatus({
                                    layerElements: imageLabelStore.status.layerElements
                                });
                            }}>
                                    {(_a = classification.options) === null || _a === void 0 ? void 0 : _a.map(option => (<Radio className="radio-option" key={option.key} value={option.key}>
                                        {option.locale || option.key}
                                      </Radio>))}
                                  </Radio.Group>
                                </div>);
                            break;
                        }
                        case ClassificationTool.Checkbox: {
                            content = (<div key={classification.key} className="classification-checkbox">
                                  <div className="checkbox-header">{classification.locale || classification.key}</div>
                                  <Checkbox.Group className="checkbox-group" value={matchRes.value} onChange={checkedValue => {
                                matchRes.value = checkedValue;
                                imageLabelStore.setStatus({
                                    layerElements: imageLabelStore.status.layerElements
                                });
                            }}>
                                    {(_b = classification.options) === null || _b === void 0 ? void 0 : _b.map(option => (<Checkbox className="checkbox-option" key={option.key} value={option.key}>
                                        {option.locale || option.key}
                                      </Checkbox>))}
                                  </Checkbox.Group>
                                </div>);
                            break;
                        }
                        case ClassificationTool.Text: {
                            content = (<div key={classification.key} className="classification-text">
                                  <div className="text-header">{classification.locale || classification.key}</div>
                                  <EventBlock blockEvent="keydown">
                                    <Input value={matchRes.value} size="small" onChange={event => {
                                matchRes.value = event.target.value;
                                imageLabelStore.setStatus({
                                    layerElements: imageLabelStore.status.layerElements
                                });
                            }}/>
                                  </EventBlock>
                                </div>);
                            break;
                        }
                        default:
                            return;
                    }
                    return (<React.Fragment key={index}>
                              {index !== 0 && <Divider style={{ margin: "2px 0" }}/>}
                              {content}
                            </React.Fragment>);
                })}
                      </div>
                    </div>);
            })}
                </div>
              </Collapse.Panel>);
        })}
        </Collapse>
      </div>);
    }
    render() {
        var _a, _b, _c, _d, _e, _f, _g;
        const detectionElements = imageLabelStore.status.layerElements.filter(e => e.label.type === "detection");
        const detectionKeys = _.uniq(detectionElements.map(item => item.label.key));
        const anchorElements = imageLabelStore.anchorElements;
        const anchorKeys = _.uniq(anchorElements.map(item => item.label.key));
        const segmentElements = imageLabelStore.status.layerElements.filter(e => /^mask-/.test(e.label.type));
        const segmentKeys = _.uniq(segmentElements.map(item => item.label.key));
        const hasSegment = !!((_a = imageLabelStore.status.selectableLabel.segments) === null || _a === void 0 ? void 0 : _a.length);
        const hasSegmentPlaceHolder = !!((_d = (_c = (_b = imageLabelStore.status) === null || _b === void 0 ? void 0 : _b.rawResource) === null || _c === void 0 ? void 0 : _c.annotation) === null || _d === void 0 ? void 0 : _d._segmentPlaceholders);
        const hasSegment3D = !!((_e = imageLabelStore.status.selectableLabel.segments3D) === null || _e === void 0 ? void 0 : _e.length);
        const hasDetection3D = !!((_f = imageLabelStore.status.selectableLabel.detections3D) === null || _f === void 0 ? void 0 : _f.length);
        const title = (<Row justify="space-between">
        标注对象
        {hasSegmentPlaceHolder && (<Button size="small" onClick={() => {
            imageLabelStore.status.hiddenDetection = !imageLabelStore.status.hiddenDetection;
            imageLabelStore.painterWrapperRef.rerender();
        }}>
            隐藏检测框
          </Button>)}
        {hasSegment && (<span onClick={() => {
            imageLabelStore.painterWrapperRef.showComparisonDialog();
        }}>
            <svg viewBox="0 0 1024 1024" version="1.1" width="1.4em" height="1.5em">
              <path d="M384 885.76h245.76v-51.2H384v51.2z m476.16-737.28H153.6c-35.84 0-66.56 25.6-66.56 61.44v517.12c0 35.84 30.72 61.44 66.56 61.44h711.68c35.84 0 66.56-25.6 66.56-61.44V209.92c-5.12-35.84-35.84-61.44-71.68-61.44z m15.36 588.8H138.24V194.56h742.4v542.72z m-46.08-491.52H481.28v445.44h343.04V245.76z m0 0" p-id="47866"></path>
            </svg>
          </span>)}
      </Row>);
        return (<div key={(_g = imageLabelStore.status.curResource) === null || _g === void 0 ? void 0 : _g.id} className="label-display-component">
        {hasSegment3D ? (<Segment3DPanel></Segment3DPanel>) : hasDetection3D ? (<Detection3DPanel></Detection3DPanel>) : (<Card className="annotations-card" title={title} bodyStyle={{ padding: "8px" }}>
            {detectionKeys.length === 0 &&
            anchorKeys.length === 0 &&
            segmentKeys.length === 0 &&
            !imageLabelStore.status.segments3D && (<div style={{ textAlign: "center", margin: "10px 0" }}>暂无标注对象</div>)}
            {detectionKeys.length > 0 && (<Collapse key={detectionKeys.length} defaultActiveKey={detectionKeys} expandIconPosition="end" className="label-display-detection">
                {detectionKeys.map(groupKey => {
            const matchLabel = _.find(imageLabelStore.status.selectableLabel.detections, item => item.key === groupKey);
            const matchLayerElements = detectionElements.filter(i => i.label.key === groupKey);
            if (matchLabel.classifications.length > 0) {
                return this.multiClassificationPanel(matchLabel, matchLayerElements);
            }
            else {
                return this.zeroClassificationPanel(matchLabel, matchLayerElements);
            }
        })}
              </Collapse>)}
            {anchorKeys.length > 0 && this.renderAnchor()}
            {this.renderSegment(segmentKeys, segmentElements)}
          </Card>)}
        <div className="tools">
          <Button disabled={imageLabelStore.status.isResourceLoading || imageLabelStore.status.readonly} onClick={async () => {
            if (await imageLabelStore.saveCurResourceAnnotation()) {
                message.success("保存成功！");
            }
        }}>
            保存
          </Button>
          <Button onClick={() => {
            this.setState({
                isPreviewVisible: true,
                annotation: JSON.stringify(imageLabelStore.getAnnotation(), null, 2)
            });
        }} style={{ zIndex: 100 }}>
            预览
          </Button>
          <Drawer open={this.state.isPreviewVisible} width="35vw" title="标注预览" placement="right" onClose={() => {
            this.setState({
                isPreviewVisible: false
            });
        }}>
            <pre>{this.state.annotation}</pre>
          </Drawer>
        </div>
      </div>);
    }
};
LabelDisplay = __decorate([
    observer
], LabelDisplay);
export { LabelDisplay };
class Segment3DItem extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            visible: true
        };
    }
    render() {
        var _a;
        return (<div className="layer-element-item">
        <div className="extra" style={{ float: "right" }}>
          <IconFont style={{ marginLeft: "6px" }} type={this.state.visible ? "icon-eye-open" : "icon-eye-off"} onClick={e => {
            const visible = !this.state.visible;
            this.setState({
                visible
            });
            if (visible) {
                imageLabelStore.emit("show-points", this.props.pointIndices);
            }
            else {
                imageLabelStore.emit("hidden-points", this.props.pointIndices);
            }
        }}/>
        </div>
        <div>
          {(_a = this.props.label) === null || _a === void 0 ? void 0 : _a.key} {this.props.pointIndices.length}
        </div>
      </div>);
    }
}
export class Segment3DPanel extends React.Component {
    constructor() {
        super(...arguments);
        this.pcdLoader = new THREE.PCDLoader();
        this.state = {
            resourceIndex: undefined
        };
    }
    renderSegment3D(segments3D = {}) {
        const { label = [] } = segments3D;
        const arr = label.map((v, index) => ({ label: v, index }));
        const labelObj = _.groupBy(arr, v => v.label);
        const keys = Object.keys(labelObj);
        const targetKeys = _.pull(keys, "-1");
        const labels = imageLabelStore.status.selectableLabel.segments3D;
        return (<div className="label-display-segment">
        {targetKeys.map(key => {
            const matchLabel = labels[parseInt(key)];
            const value = labelObj[key];
            return (<div className="layer-elements-wrapper" key={key}>
              <Segment3DItem label={matchLabel} pointIndices={value.map(v => v.index)}></Segment3DItem>
            </div>);
        })}
      </div>);
    }
    onChange(resourceIndex) {
        var _a;
        const curResourceLabel = (_a = imageLabelStore.status.segments3D) === null || _a === void 0 ? void 0 : _a.label;
        const resource = toJS(imageLabelStore.status.resources[resourceIndex]);
        new Promise(resolve => {
            if (curResourceLabel === null || curResourceLabel === void 0 ? void 0 : curResourceLabel.some(v => v > -1)) {
                return Modal.confirm({
                    content: "当前点云图上已有标注，是否需要继承？",
                    onOk: () => resolve(true),
                    onCancel: () => resolve(false)
                });
            }
            resolve(true);
        }).then(next => {
            var _a;
            if (next) {
                this.setState({ resourceIndex });
                this.pcdLoader.load((_a = resource.pcd) === null || _a === void 0 ? void 0 : _a.url, (pcd) => {
                    var _a;
                    const ret = this.intersection(imageLabelStore.editor3DRef.pcd, pcd, ((_a = resource.annotation.segments3D) === null || _a === void 0 ? void 0 : _a.label) || []);
                    imageLabelStore.editor3DRef.disabled = true;
                    imageLabelStore.editor3DRef.changeLabel(ret.filter(v => v.label > -1));
                });
            }
        });
    }
    onSubmit() {
        imageLabelStore.editor3DRef.disabled = false;
        const resourceIndex = this.state.resourceIndex;
        const { id } = imageLabelStore.status.curResource.pcd;
        imageLabelStore.updatePcd({ id, inheritedBy: imageLabelStore.status.resources[resourceIndex].id });
    }
    intersection(pcd1, pcd2, pcd2Label) {
        const position1 = _.chunk(pcd1.position, 3).map((v, idx) => ({ x: v[0], y: v[1], z: v[2], index: idx }));
        const position2 = _.chunk(pcd2.position, 3).map((v, idx) => ({ x: v[0], y: v[1], z: v[2], index: idx }));
        const result = [];
        const distance = function (a, b) {
            return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2) + Math.pow(a.z - b.z, 2));
        };
        const tree = new kdTree(position2, distance, ["x", "y", "z"]);
        position1.forEach((p, idx) => {
            const nearest = tree.nearest(p, 1)[0];
            if (nearest[1] <= imageLabelStore.inheritThreshold) {
                result.push({
                    index: idx,
                    label: pcd2Label[nearest[0].index]
                });
            }
        });
        return result;
    }
    render() {
        var _a, _b, _c, _d;
        const { resourceIndex } = this.state;
        return (<div className="content ugly-content" style={{ backgroundColor: "#fff" }}>
        <Card className="annotations-card" title="标注选择" bordered={false} bodyStyle={{ padding: "8px" }}>
          <Select value={resourceIndex} onSelect={value => this.onChange(value)} style={{ width: 250, marginRight: 10 }}>
            {imageLabelStore.status.resources.map((v, i) => {
            var _a;
            return (<Select.Option disabled={i == imageLabelStore.status.curOffset} key={i} value={i}>{`${i + 1}. ${getName((_a = v.pcd) === null || _a === void 0 ? void 0 : _a.url)} ${imageLabelStore.status.curOffset == i ? "(正在标注)" : ""}`}</Select.Option>);
        })}
          </Select>
          <Button type="primary" onClick={() => this.onSubmit()}>
            确定
          </Button>
        </Card>
        <Card className="annotations-card" title="标注对象" bordered={false} bodyStyle={{ padding: "8px" }}>
          {this.renderSegment3D(imageLabelStore.status.segments3D)}
        </Card>
        <div className="ugly-points-length">
          已标注点数/总点数:{" "}
          {`${(_b = (_a = imageLabelStore.editor3DRef) === null || _a === void 0 ? void 0 : _a.label) === null || _b === void 0 ? void 0 : _b.filter(v => v > -1).length}/${(_d = (_c = imageLabelStore.status) === null || _c === void 0 ? void 0 : _c.pcd) === null || _d === void 0 ? void 0 : _d.header.points}`}
        </div>
      </div>);
    }
}
let Detection3DPanel = class Detection3DPanel extends React.Component {
    constructor() {
        super(...arguments);
        this.batchAction = (label, boxes) => {
            const status = imageLabelStore.status;
            return (<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden"
            }}>{`${label.locale || label.key} (${boxes.length})`}</div>
        <div onClick={e => e.stopPropagation()}>
          <Popover placement="bottomRight" title="批量操作" content={<div style={{ display: "grid", gridTemplateColumns: "1fr", gridRowGap: "4px" }}>
                <Button onClick={() => {
                boxes.forEach(b => {
                    imageLabelStore.editor3DRef.setBoxVisible(b.id, false);
                });
            }}>
                  隐藏
                </Button>
                <Button onClick={() => {
                boxes.forEach(b => {
                    imageLabelStore.editor3DRef.setBoxVisible(b.id, true);
                });
            }}>
                  显示
                </Button>
                <Button key="delete" onClick={() => {
                boxes.forEach(b => {
                    imageLabelStore.editor3DRef.removeBox(b.id);
                });
            }}>
                  删除
                </Button>
              </div>}>
            <BarsOutlined />
          </Popover>
        </div>
      </div>);
        };
        this.onItemClick = id => {
            imageLabelStore.status.selectedBoxId = id;
        };
        this.onVisibleClick = (e, id, visible) => {
            e.stopPropagation();
            imageLabelStore.editor3DRef.setBoxVisible(id, visible);
        };
    }
    render() {
        const boxes = imageLabelStore.status.boxes;
        const boxObjs = _.groupBy(boxes, v => v.label.key);
        const keys = Object.keys(boxObjs);
        return (<div className="content ugly-content" style={{ backgroundColor: "#fff" }}>
        <Card className="annotations-card" title="标注对象" bordered={false} bodyStyle={{ padding: "8px" }}>
          <div className="label-display-detection">
            <Collapse key={keys.length} defaultActiveKey={keys} expandIconPosition="end" className="label-display-detection">
              {keys.map(key => {
            const label = _.find(imageLabelStore.status.selectableLabel.detections3D, v => v.key === key);
            const boxes = boxObjs[key];
            const childs = boxes.map(box => {
                return (<div style={Object.assign({}, (imageLabelStore.status.selectedBoxId == box.id
                    ? {
                        backgroundColor: Color(label.color)
                            .fade(0.75)
                            .toString()
                    }
                    : null))} onClick={() => {
                    this.onItemClick(box.id);
                }} id={box.id + ""} className="layer-element-item" key={box.id}>
                      <div className="extra">
                        <IconFont onClick={e => {
                    this.onVisibleClick(e, box.id, !box.visible);
                }} type={box.visible ? "icon-eye-open" : "icon-eye-off"}/>
                        <IconFont onClick={e => {
                    box.titleVisible = !box.titleVisible;
                }} type={box.titleVisible ? "icon-eye-open" : "icon-eye-off"}/>
                      </div>
                      <div title={box.id + ""} className="label-id">
                        {box._uid}
                      </div>
                    </div>);
            });
            return (<Collapse.Panel className="zero-classification-panel" header={this.batchAction(label, boxes)} key={key}>
                    <div className="layer-elements-wrapper">{childs}</div>
                  </Collapse.Panel>);
        })}
            </Collapse>
          </div>
        </Card>
      </div>);
    }
};
Detection3DPanel = __decorate([
    observer
], Detection3DPanel);
export { Detection3DPanel };
function getName(url) {
    const path = url.split("?")[0];
    return basename(path, extname(path));
}

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { Button, Card, PageHeader, Table, Dropdown, Tag } from "antd";
import { routerStore } from "../../../../store/routerStore";
import { labellingAssessmentPoliciesStore } from "./LabellingAssessmentPoliciesStore";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
let LabellingAssessmentPolicies = class LabellingAssessmentPolicies extends React.Component {
    constructor() {
        super(...arguments);
        this.columns = [
            {
                title: "方法名称",
                ellipsis: true,
                dataIndex: "name"
            },
            {
                title: "Version",
                align: "center",
                dataIndex: "version",
                width: 100
            },
            {
                title: "Tags",
                dataIndex: "tags",
                width: 240,
                ellipsis: true,
                render: (value, record) => {
                    return record.tags.map((tag, index) => (<Tag color="blue" key={index}>
            {tag}
          </Tag>));
                }
            },
            {
                title: "Commit ID",
                ellipsis: true,
                dataIndex: "commitId"
            },
            {
                title: "更新日期",
                align: "center",
                dataIndex: "createdAt",
                width: 120,
                render: (value, record) => {
                    return moment(record.createdAt).format("YYYY-MM-DD");
                }
            },
            {
                title: "操作",
                align: "center",
                width: 160,
                render: (value, record) => {
                    return (<div>
            <a onClick={() => {
                        routerStore.push(`/manage/labelling_assessment_policy/${record.id}/detail`);
                    }}>
              查看
            </a>
            <Dropdown menu={{
                        items: [
                            {
                                key: "1",
                                onClick() {
                                    labellingAssessmentPoliciesStore.downloadPolicyScript(record.id);
                                },
                                label: "方法脚本"
                            },
                            {
                                key: "2",
                                onClick() {
                                    labellingAssessmentPoliciesStore.downloadGroundTruthExample(record.id);
                                },
                                label: "基准标注"
                            },
                            {
                                key: "3",
                                onClick() {
                                    labellingAssessmentPoliciesStore.downloadAssessingExample(record.id);
                                },
                                label: "待评价标注"
                            }
                        ]
                    }}>
              <Button type="link" size="small" className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                下载
              </Button>
            </Dropdown>
          </div>);
                }
            }
        ];
    }
    componentDidMount() {
        labellingAssessmentPoliciesStore.loadData();
    }
    render() {
        const status = toJS(labellingAssessmentPoliciesStore.status);
        return (<PageHeader title="评估方法列表" style={{ background: "#FFF" }}>
        <Card>
          <Table loading={status.isLoading} rowKey="id" bordered={true} style={{ marginTop: "20px" }} columns={this.columns} dataSource={labellingAssessmentPoliciesStore.filteredLabellingAssessmentPolicies} pagination={{ showSizeChanger: false }}/>
        </Card>
      </PageHeader>);
    }
};
LabellingAssessmentPolicies = __decorate([
    observer
], LabellingAssessmentPolicies);
export { LabellingAssessmentPolicies };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j;
import BasicStore from "../../../../store/BasicStore";
import { action, computed, observable, toJS } from "mobx";
import { Api } from "../../../../api/Api";
import { IResource } from "../../../../definition/entity/resource";
import { routerStore } from "../../../../store/routerStore";
import * as _ from "lodash";
import { ILabelSandBags } from "../../../../definition/entity/label-sand-bags";
import { isLabelTaskLabelEqual } from "../../../../util/label-task-compare";
import { randomString } from "util/random-string";
import { LabelTask, DetectionTool, ILabelTask } from "../../../../definition/entity/label-task";
import { appStore } from "store/AppStore";
import readLine from "util/readLine";
class Status {
    constructor() {
        this.taskTemplates = [];
        this.taskBusinesses = [];
        this.sandBags = [];
        this.assessmentPolicies = [];
        this.defaultEditorLabel = null;
        this.propertySet = new Set();
        this.propertySelected = [];
        this.fileName = "";
        this.displayResourceUrls = [];
        this.loading = false;
        this.name = "";
        this.businessId = undefined;
        this.level = ILabelTask.Level.Third;
        this.taskDeadline = null;
        this.labelDeadline = null;
        this.taskTemplateId = undefined;
        this.remark = "";
        this.resourceMime = IResource.Mime.Image;
        this.resources = [];
        this.perPackageCount = 2000;
        this.taskType = ILabelTask.Type.Normal;
        this.curMixSandBag = null;
        this.curMixLabelTask = null;
        this.mixRatio = null;
        this.mixPolicyThresholds = [];
        this.curMixAssessmentPolicyId = undefined;
        this.isMixLabelModalVisible = false;
        this.extractRatio = null;
        this.extractPolicyThresholds = [];
        this.curExtractAssessmentPolicyId = undefined;
        this.isEditorValid = false;
        this.isCreatingLabelTask = false;
        this.videoExtractPolicyThresholds = [];
        this.needAllLabeled = false;
        this.biz = {
            headOffset: 30,
            tailOffset: 30
        };
        this.dataoceanBlobs = [];
        this.resourceFrom = null;
    }
}
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "taskTemplates", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "taskBusinesses", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "sandBags", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "assessmentPolicies", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "defaultEditorLabel", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Set !== "undefined" && Set) === "function" ? _a : Object)
], Status.prototype, "propertySet", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "propertySelected", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "fileName", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "displayResourceUrls", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "loading", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "name", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "businessId", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_b = typeof ILabelTask !== "undefined" && ILabelTask.Level) === "function" ? _b : Object)
], Status.prototype, "level", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_c = typeof moment !== "undefined" && moment.Moment) === "function" ? _c : Object)
], Status.prototype, "taskDeadline", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_d = typeof moment !== "undefined" && moment.Moment) === "function" ? _d : Object)
], Status.prototype, "labelDeadline", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "taskTemplateId", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_e = typeof IResource !== "undefined" && IResource.Mime) === "function" ? _e : Object)
], Status.prototype, "resourceMime", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "perPackageCount", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_f = typeof ILabelTask !== "undefined" && ILabelTask.Type) === "function" ? _f : Object)
], Status.prototype, "taskType", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_g = typeof ILabelSandBags !== "undefined" && ILabelSandBags.AsObject) === "function" ? _g : Object)
], Status.prototype, "curMixSandBag", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_h = typeof LabelTask !== "undefined" && LabelTask) === "function" ? _h : Object)
], Status.prototype, "curMixLabelTask", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "mixRatio", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "mixPolicyThresholds", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "curMixAssessmentPolicyId", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isMixLabelModalVisible", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_j = typeof ILabelTask !== "undefined" && ILabelTask.LabelTaskType) === "function" ? _j : Object)
], Status.prototype, "labelTaskType", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "extractRatio", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "extractPolicyThresholds", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "curExtractAssessmentPolicyId", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isEditorValid", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isCreatingLabelTask", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "videoExtractPolicyThresholds", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "needAllLabeled", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "biz", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "dataoceanBlobs", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "resourceFrom", void 0);
class LabelTaskCreateStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
    }
    resetStatus() {
        this.status = new Status();
    }
    async loadTaskBusinesses() {
        const taskBusinessesRes = await Api.taskBusiness.listTaskBusinesses();
        this.status.taskBusinesses = taskBusinessesRes.data;
    }
    async loadTaskTemplates() {
        const taskTemplatesRes = await Api.taskTemplate.listSimpleTaskTemplates();
        this.status.taskTemplates = taskTemplatesRes.data;
    }
    async loadSandBags() {
        let sandBagsRes = null;
        if (appStore.status.loginUser.isSystemAdmin) {
            sandBagsRes = await Api.labelSandBags.getLabelSandBags();
        }
        else {
            sandBagsRes = await Api.labelSandBags.getLabelSandBags({ creatorId: appStore.status.loginUser.id });
        }
        this.status.sandBags = sandBagsRes.data;
    }
    async loadAssessmentPolicies() {
        const assessmentPoliciesRes = await Api.labellingAssessmentPolicy.getLabellingAssessmentPolicies();
        const labellingAssessmentPoliciesResMap = new Map();
        assessmentPoliciesRes.data.forEach(item => {
            if (labellingAssessmentPoliciesResMap.has(item.name))
                return;
            labellingAssessmentPoliciesResMap.set(item.name, item);
        });
        this.status.assessmentPolicies = [...labellingAssessmentPoliciesResMap.values()];
    }
    handleChangeTemplate(templateId) {
        const taskTemplate = _.find(this.status.taskTemplates, template => {
            return template.id === templateId;
        });
        this.status.mixPolicyThresholds = [];
        this.status.extractPolicyThresholds = [];
        this.status.videoExtractPolicyThresholds = [];
        if (!taskTemplate) {
            this.status.remark = "";
            this.status.defaultEditorLabel = {
                detections: [],
                classifications: [],
                anchors: [],
                segments: [],
                segments3D: [],
                detections3D: []
            };
            this.status.curMixSandBag = null;
            this.status.taskType = ILabelTask.Type.Normal;
            this.status.curMixAssessmentPolicyId = undefined;
            this.status.curExtractAssessmentPolicyId = undefined;
            this.status.mixRatio = undefined;
            this.status.extractRatio = undefined;
            this.status.mixPolicyThresholds = [];
            this.status.extractPolicyThresholds = [];
            this.status.videoExtractPolicyThresholds = [];
        }
        else {
            const { name, label: { detections = [], classifications = [], anchors = [], segments = [], segments3D = [], detections3D = [] } = {
                detections: [],
                classifications: [],
                anchors: []
            } } = taskTemplate;
            this.status.remark = name;
            this.status.defaultEditorLabel = {
                detections: detections,
                classifications: classifications,
                anchors: anchors,
                segments,
                segments3D,
                detections3D
            };
            detections.forEach(detection => {
                this.status.mixPolicyThresholds.push({
                    uniqKey: detection.uniqKey,
                    nameConfig: { value: detection.key },
                    configs: [
                        { key: "precision", value: 0 },
                        { key: "recall", value: 0 },
                        { key: "iou", value: 0 }
                    ]
                });
                this.status.extractPolicyThresholds.push({
                    uniqKey: detection.uniqKey,
                    nameConfig: { value: detection.key },
                    configs: [
                        { key: "precision", value: 0 },
                        { key: "recall", value: 0 },
                        { key: "iou", value: 0 }
                    ]
                });
                this.status.videoExtractPolicyThresholds.push({
                    uniqKey: detection.uniqKey,
                    nameConfig: { value: detection.key },
                    configs: [
                        { key: "mota", value: 0 },
                        { key: "motp", value: 0 },
                        { key: "mt", value: 0 }
                    ]
                });
            });
            classifications.forEach(classification => {
                this.status.mixPolicyThresholds.push({
                    uniqKey: classification.uniqKey,
                    nameConfig: { value: classification.key },
                    configs: [
                        { key: "precision", value: 0 },
                        { key: "recall", value: 0 },
                        { key: "iou", value: 0 }
                    ]
                });
                this.status.extractPolicyThresholds.push({
                    uniqKey: classification.uniqKey,
                    nameConfig: { value: classification.key },
                    configs: [
                        { key: "precision", value: 0 },
                        { key: "recall", value: 0 },
                        { key: "iou", value: 0 }
                    ]
                });
                this.status.videoExtractPolicyThresholds.push({
                    uniqKey: classification.uniqKey,
                    nameConfig: { value: classification.key },
                    configs: [
                        { key: "mota", value: 0 },
                        { key: "motp", value: 0 },
                        { key: "mt", value: 0 }
                    ]
                });
            });
            if (this.status.resourceMime === IResource.Mime.Image &&
                this.status.labelTaskType === ILabelTask.LabelTaskType.Segmentation) {
                this.status.mixPolicyThresholds.push({
                    nameConfig: { value: "log" },
                    uniqKey: `mix_${randomString()}_${new Date().getUTCSeconds()}`,
                    configs: [
                        { key: "box_siou_th", value: undefined },
                        { key: "mask_siou_th", value: undefined }
                    ]
                });
            }
        }
    }
    async changeResourceFile(file) {
        var e_1, _a;
        this.status.loading = true;
        this.status.fileName = file.name;
        console.log(Date.now());
        const resourceArr = [];
        try {
            for (var _b = __asyncValues(readLine(file)), _c; _c = await _b.next(), !_c.done;) {
                const line = _c.value;
                try {
                    const resource = JSON.parse(line);
                    resourceArr.push(resource);
                }
                catch (err) {
                    console.log(err, line);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) await _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.status.resources = resourceArr;
        this.status.displayResourceUrls = resourceArr.slice(0, 100);
        this.status.propertySelected = Object.keys(resourceArr[0]);
        this.status.loading = false;
        if (resourceArr.length) {
            this.status.resourceFrom = "jsonList";
        }
        console.log(Date.now());
        console.log(resourceArr.length);
    }
    removeResourceFile() {
        this.status.fileName = null;
        this.status.resources = [];
        this.status.displayResourceUrls = [];
        this.status.propertySet = new Set();
        this.status.propertySelected = [];
    }
    chooseDataOceanBlob(resources) {
        this.status.resources = resources;
        this.status.displayResourceUrls = resources.map(i => ({ url: i.url }));
        if (resources === null || resources === void 0 ? void 0 : resources.length) {
            const tmpPropertySet = new Set();
            tmpPropertySet.add("url").add("meta");
            this.status.propertySet = tmpPropertySet;
            this.status.propertySelected = ["url", "meta"];
        }
        else {
            this.status.propertySet = new Set();
            this.status.propertySelected = [];
        }
    }
    async handleChangeSandPackage(sandBagId) {
        this.status.isMixLabelModalVisible = false;
        this.status.curMixSandBag = _.find(this.status.sandBags, i => i.id === sandBagId);
    }
    checkIsLabelEqual(curLabel) {
        return isLabelTaskLabelEqual(_.cloneDeep(toJS(curLabel)), _.cloneDeep(toJS(this.status.curMixSandBag.label)));
    }
    validateAnnotation(annotation) {
        return true;
        const { detections } = annotation;
        const fieldsMap = {
            [DetectionTool.Rectangle]: ["x", "y"],
            [DetectionTool.Dot]: ["cx", "cy"],
            [DetectionTool.Path]: [],
            [DetectionTool.Chunk]: []
        };
        for (let i = 0; i < (detections === null || detections === void 0 ? void 0 : detections.length); i++) {
            const detection = detections[i];
            let fields = detection.tool ? fieldsMap[detection.tool] : Object.keys(detection.value || {});
            const hasNull = fields.map(field => detection.value[field]).some(v => v == null);
            if (hasNull) {
                return false;
            }
        }
        return true;
    }
    async createLabelTask(label) {
        const { name, businessId, remark, resourceMime, level, taskDeadline, labelDeadline, taskTemplateId, resources, perPackageCount, curMixSandBag, mixRatio, curMixAssessmentPolicyId, mixPolicyThresholds, extractRatio, curExtractAssessmentPolicyId, extractPolicyThresholds, videoExtractPolicyThresholds, labelTaskType, biz, dataoceanBlobs } = this.status;
        const _resources = resources.map(resource => {
            const res = {};
            this.status.propertySelected.forEach(key => {
                res[key] = resource[key];
            });
            return res;
        });
        if (this.status.propertySelected.includes("enableTransform")) {
            _resources.forEach(resource => {
                if (resource.annotation) {
                    resource.annotation._segmentPlaceholders = resource.annotation.detections;
                }
            });
        }
        const req = Object.assign({
            type: this.status.taskType,
            name,
            businessId,
            remark,
            resourceMime,
            level,
            taskDeadline,
            labelDeadline,
            taskTemplateId,
            label,
            resources: _resources,
            perPackageCount,
            needAllLabeled: this.status.needAllLabeled ? 1 : 0,
            labelTaskType,
            biz,
            dataoceanBlobs
        }, this.status.taskType === ILabelTask.Type.NewMix && {
            mix: {
                sandBagId: curMixSandBag === null || curMixSandBag === void 0 ? void 0 : curMixSandBag.id,
                ratio: mixRatio / 100,
                policyId: curMixAssessmentPolicyId,
                policyThresholds: mixPolicyThresholds.map(item => {
                    const { uniqKey, nameConfig } = item, rest = __rest(item, ["uniqKey", "nameConfig"]);
                    const keys = rest.configs.map(v => v.key);
                    const values = rest.configs.map(v => v.value);
                    const configs = _.zipObject(keys, values);
                    return {
                        [`${nameConfig.value}`]: configs
                    };
                })
            }
        }, this.status.taskType === ILabelTask.Type.Extract && {
            extract: {
                ratio: extractRatio / 100,
                policyId: curExtractAssessmentPolicyId,
                policyThresholds: this.status.resourceMime === IResource.Mime.Image
                    ? extractPolicyThresholds.map(item => {
                        const { uniqKey, nameConfig } = item, rest = __rest(item, ["uniqKey", "nameConfig"]);
                        const keys = rest.configs.map(v => v.key);
                        const values = rest.configs.map(v => v.value);
                        const configs = _.zipObject(keys, values);
                        return {
                            [`${nameConfig.value}`]: configs
                        };
                    })
                    : videoExtractPolicyThresholds.map(item => {
                        const { uniqKey, nameConfig } = item, rest = __rest(item, ["uniqKey", "nameConfig"]);
                        const keys = rest.configs.map(v => v.key);
                        const values = rest.configs.map(v => v.value);
                        const configs = _.zipObject(keys, values);
                        return {
                            [`${nameConfig.value}`]: configs
                        };
                    })
            }
        });
        const createLabelTaskRes = await Api.labelTask.createLabelTask(req);
        this.status.isCreatingLabelTask = false;
        if (createLabelTaskRes.code === 200) {
            routerStore.goBack();
        }
    }
    get isEnableCreate() {
        const { isEditorValid, name, displayResourceUrls, mixRatio, curMixSandBag, taskType, curMixAssessmentPolicyId, mixPolicyThresholds, extractPolicyThresholds, extractRatio, curExtractAssessmentPolicyId, resourceMime, videoExtractPolicyThresholds } = toJS(this.status);
        return (isEditorValid &&
            name &&
            displayResourceUrls.length &&
            (taskType === ILabelTask.Type.Normal ||
                (taskType === ILabelTask.Type.NewMix &&
                    mixRatio && (curMixSandBag === null || curMixSandBag === void 0 ? void 0 : curMixSandBag.id) &&
                    curMixAssessmentPolicyId &&
                    mixPolicyThresholds.length) ||
                (taskType === ILabelTask.Type.Extract &&
                    resourceMime === IResource.Mime.Image &&
                    extractRatio &&
                    extractPolicyThresholds.length &&
                    curExtractAssessmentPolicyId) ||
                (taskType === ILabelTask.Type.Extract &&
                    resourceMime === IResource.Mime.Video &&
                    videoExtractPolicyThresholds.length)));
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], LabelTaskCreateStore.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], LabelTaskCreateStore.prototype, "resetStatus", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], LabelTaskCreateStore.prototype, "isEnableCreate", null);
export const labelTaskCreateStore = new LabelTaskCreateStore();

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Col, Input, Modal, Row, Table, Tabs, Tag, Select } from "antd";
import { Api } from "api/Api";
import { GetBubbleReq } from "atom-proto/web/dataocean/bubble_pb";
import { ListDropletsCondition, ListDropletsReq } from "atom-proto/web/dataocean/droplet_pb";
import { MIMEType } from "atom-proto/web/dataocean/global_pb";
import { ID, PagerReq } from "atom-proto/web/meta/meta_pb";
import { toJS } from "mobx";
import React from "react";
import { buildObject } from "util/common";
import moment from "moment";
import axios from "axios";
import * as _ from "lodash";
import { DatasetKind, ListDatasetContentsReq, ListDatasetsReq } from "atom-proto/web/dataocean/dataset_pb";
import { appStore } from "store/AppStore";
var TabKeys;
(function (TabKeys) {
    TabKeys["LabelX"] = "DATASET_KIND_LABEL_TASK";
    TabKeys["WORK_BENCH"] = "DATASET_KIND_WORK_BENCH";
})(TabKeys || (TabKeys = {}));
const KeyMaps = {
    [DatasetKind.DATASET_KIND_WORK_BENCH]: TabKeys.WORK_BENCH,
    [DatasetKind.DATASET_KIND_LABEL_TASK]: TabKeys.LabelX
};
var MIME_TYPE;
(function (MIME_TYPE) {
    MIME_TYPE["Image"] = "image";
    MIME_TYPE["Video"] = "video";
})(MIME_TYPE || (MIME_TYPE = {}));
export default class DataoceanDialog extends React.Component {
    constructor() {
        super(...arguments);
        this.userInfo = {};
        this.state = {
            [TabKeys.LabelX]: {
                blobs: [],
                droplets: [],
                page: 1,
                pageSize: 10,
                total: null,
                keyword: "",
                searchType: 1,
                value: [],
                loading: false,
                marker: "",
                more: true
            },
            [TabKeys.WORK_BENCH]: {
                blobs: [],
                droplets: [],
                page: 1,
                pageSize: 10,
                total: null,
                keyword: "",
                searchType: 1,
                value: [],
                loading: false,
                marker: "",
                more: true
            }
        };
        this.columns = [
            {
                title: "数据集名称",
                render(text, record) {
                    return record.meta.name;
                }
            },
            {
                title: "资源数量",
                width: 120,
                render(text, record) {
                    return record.status.count;
                }
            },
            {
                title: "标签",
                render(text, record) {
                    return (<div>
            {record.meta.tagsMap.map((v, index) => {
                        return (<span>
                  <Tag key={index}>{v[0]}</Tag>
                </span>);
                    })}
          </div>);
                }
            },
            {
                title: "创建时间",
                render(text, record) {
                    return moment(record.meta.createTime.seconds * 1000).format("YYYY-MM-DD HH:mm:ss");
                }
            }
        ];
    }
    async init() {
        try {
            this.userInfo = await this.getUserInfo();
        }
        catch (err) { }
        this.onSearch(DatasetKind.DATASET_KIND_LABEL_TASK, 1);
    }
    onSearch(kind, page, pageSize) {
        const target = this.state[KeyMaps[kind]];
        target.page = page || target.page;
        target.pageSize = pageSize || target.pageSize;
        target.marker = "";
        target.blobs = [];
        this.onPageChange(kind, page, pageSize);
    }
    async onPageChange(kind, page, pageSize) {
        const target = this.state[KeyMaps[kind]];
        target.loading = true;
        this.setState(Object.assign({}, this.state));
        page = page || target.page;
        pageSize = pageSize || target.pageSize;
        const keyword = target.searchType === 1 ? target.keyword : null;
        const tag = target.searchType === 2 ? target.keyword : null;
        const blobs = await this.fetchBlobs(page, pageSize, keyword, tag, kind, target.marker);
        target.blobs = [...blobs.datasetsList];
        target.total = blobs.pager.total;
        if (blobs.datasetsList.length < pageSize) {
            target.more = false;
        }
        else {
            target.more = true;
        }
        target.loading = false;
        this.setState(Object.assign({}, this.state));
    }
    async fetchBlobs(page, pageSize, keyword, tag, kind, marker) {
        const res = await Api.dataocean.dataset.listDatasets(buildObject(new ListDatasetsReq(), req => {
            req.setPager(buildObject(new PagerReq(), pager => {
                pager.setPage(page - 1);
                pager.setLimit(pageSize);
            }));
            req.setKind(kind);
            req.setNamePattern(keyword);
            req.setCreator(appStore.status.loginUser.key.username);
        }));
        return res.toObject();
    }
    async fetchDroplets(creator, blobId, kind, pool, marker) {
        let result = [];
        const req = buildObject(new ListDropletsReq(), req => {
            req.setCondition(buildObject(new ListDropletsCondition(), condition => {
                condition.setBlobId(blobId);
                condition.setMimeType(buildObject(new MIMEType(), mimeType => {
                    mimeType.setType(this.props.mimeType);
                }));
                condition.setPool(pool);
            }));
            req.setPager(buildObject(new PagerReq(), pager => {
                marker && pager.setMarker(marker);
            }));
        });
        try {
            const data = await Api.dataocean.droplet.listDroplets(req);
            console.log("ddd", data.toObject());
            const res = data.toObject();
            const droplets = res.dropletsList;
            const hasMore = res.pager.marker !== marker;
            result = result.concat(droplets);
            if (hasMore) {
                const droplets = await this.fetchDroplets(creator, blobId, kind, pool, res.pager.marker);
                result = result.concat(droplets);
            }
            return result;
        }
        catch (error) {
            console.log(error.message);
        }
    }
    async fetchBubble(name) {
        console.log("bubbleName", name);
        const data = await Api.dataocean.bubble.getBubble(buildObject(new GetBubbleReq(), bubble => {
            bubble.setName(name);
        }));
        console.log("bubbleName", name, "done");
        return data.toObject();
    }
    async fetchDatasetContents(dataset) {
        const res = await Api.dataocean.dataset.listDatasetContents(buildObject(new ListDatasetContentsReq(), req => {
            req.setPage(buildObject(new PagerReq(), pager => {
                pager.setWantTotal(true);
                pager.setLimit(100000000);
            }));
            req.setId(buildObject(new ID(), id => {
                id.setHex(dataset.meta.id.hex);
            }));
        }));
        return res.toObject();
    }
    async getUserInfo() {
        return axios
            .get(joinPaths([window.config.dataocean_grpc_endpoint, "v2/login/identity"]), {
            headers: {}
        })
            .then(res => {
            if (res.status === 200) {
                return res.data;
            }
        });
    }
    onDelete(key, blob) {
        _.remove(this.state[key].value, blob);
        this.setState(Object.assign({}, this.state));
        const value1 = this.state[TabKeys.LabelX].value || [];
        const value2 = this.state[TabKeys.WORK_BENCH].value || [];
        this.props.onSubmit({ [TabKeys.LabelX]: value2, [TabKeys.WORK_BENCH]: value1 });
    }
    render() {
        const _a = this.props, { title, onCancel, onSubmit } = _a, rest = __rest(_a, ["title", "onCancel", "onSubmit"]);
        return (<div className="dataocean-dialog">
        <Modal title="Dataocean-数据集" {...rest} onCancel={() => {
            onCancel();
        }} onOk={() => {
            const value1 = this.state[TabKeys.LabelX].value || [];
            const value2 = this.state[TabKeys.WORK_BENCH].value || [];
            onSubmit({ [TabKeys.LabelX]: value2, [TabKeys.WORK_BENCH]: value1 });
        }}>
          <Tabs onChange={key => {
            this.onSearch(DatasetKind[key], 1);
        }} defaultActiveKey={TabKeys.LabelX} animated={false}>
            <Tabs.TabPane tab="labelx" key={TabKeys.LabelX}>
              <div>
                <Row style={{ marginBottom: 5 }}>
                  <Col span={10}>
                    <Input.Search placeholder="请输入关键词过滤" onSearch={value => {
            this.state[TabKeys.LabelX].keyword = value;
            this.setState(Object.assign({}, this.state));
            this.onSearch(DatasetKind.DATASET_KIND_LABEL_TASK, 1);
        }}/>
                  </Col>
                </Row>
                <Table scroll={{ y: 400 }} rowKey={record => record.meta.id.hex} columns={this.columns} dataSource={toJS(this.state[TabKeys.LabelX].blobs)} rowSelection={{
            selectedRowKeys: this.state[TabKeys.LabelX].value.map(v => v.meta.id.hex),
            type: "checkbox",
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    [TabKeys.LabelX]: Object.assign(Object.assign({}, this.state[TabKeys.LabelX]), { value: selectedRows })
                });
            },
            getCheckboxProps(record) {
                if (record.status.resourceCount <= 0) {
                    return {
                        disabled: true
                    };
                }
            }
        }} pagination={{
            current: this.state[TabKeys.LabelX].page,
            pageSize: this.state[TabKeys.LabelX].pageSize,
            total: this.state[TabKeys.LabelX].total,
            size: "small",
            onChange: (page, pageSize) => {
                this.state[TabKeys.LabelX].page = page;
                this.state[TabKeys.LabelX].pageSize = pageSize;
                this.setState(Object.assign({}, this.state));
                this.onPageChange(DatasetKind.DATASET_KIND_LABEL_TASK, page, pageSize);
            }
        }}/>
                
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="数据工作台" key={TabKeys.WORK_BENCH}>
              <Row style={{ marginBottom: 5 }}>
                <Col span={12}>
                  <Input.Group compact>
                    <Select value={this.state[TabKeys.WORK_BENCH].searchType} style={{ width: "30%" }} onChange={(value) => {
            this.state[TabKeys.WORK_BENCH].searchType = value;
            this.setState(Object.assign({}, this.state));
        }}>
                      <Select.Option value={1}>数据集名称</Select.Option>
                      <Select.Option value={2}>标签</Select.Option>
                    </Select>
                    <Input.Search style={{ width: "70%" }} placeholder="请输入关键字搜索" onSearch={value => {
            this.state[TabKeys.WORK_BENCH].keyword = value;
            this.setState(Object.assign({}, this.state));
            this.onSearch(DatasetKind.DATASET_KIND_WORK_BENCH, 1);
        }}/>
                  </Input.Group>
                </Col>
              </Row>
              <Table scroll={{ y: 400 }} loading={this.state[TabKeys.WORK_BENCH].loading} rowKey={record => record.meta.id.hex} columns={this.columns} dataSource={toJS(this.state[TabKeys.WORK_BENCH].blobs)} rowSelection={{
            selectedRowKeys: this.state[TabKeys.WORK_BENCH].value.map(v => v.meta.id.hex),
            type: "checkbox",
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    [TabKeys.WORK_BENCH]: Object.assign(Object.assign({}, this.state[TabKeys.WORK_BENCH]), { value: selectedRows })
                });
            },
            getCheckboxProps(record) {
                if (record.status.resourceCount <= 0) {
                    return {
                        disabled: true
                    };
                }
            }
        }} pagination={{
            current: this.state[TabKeys.WORK_BENCH].page,
            pageSize: this.state[TabKeys.WORK_BENCH].pageSize,
            total: this.state[TabKeys.WORK_BENCH].total,
            size: "small",
            onChange: (page, pageSize) => {
                this.state[TabKeys.WORK_BENCH].page = page;
                this.state[TabKeys.WORK_BENCH].pageSize = pageSize;
                this.setState(Object.assign({}, this.state));
                this.onPageChange(DatasetKind.DATASET_KIND_WORK_BENCH, page, pageSize);
            }
        }}/>
              
            </Tabs.TabPane>
          </Tabs>
        </Modal>
      </div>);
    }
}
export const joinPaths = (paths) => paths.join("/").replace(/\/\/+/g, "/");

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { Button, Card, Checkbox, Form, Input, PageHeader, Radio, Table, Upload } from "antd";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { routerStore } from "../../../../store/routerStore";
import { labelSandBagsCreateStore } from "./LabelSandBagsCreateStore";
import { ISand } from "../../../../definition/entity/label-sand";
let LabelSandBagsCreate = class LabelSandBagsCreate extends React.Component {
    componentWillUnmount() {
        labelSandBagsCreateStore.resetStatus();
    }
    render() {
        const status = labelSandBagsCreateStore.status;
        return (<PageHeader title="创建沙子包" style={{ background: "#FFF" }} onBack={() => {
            routerStore.push("/manage/label_sand_bags");
        }}>
        <Card>
          <Form labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} hideRequiredMark={true}>
            <Form.Item label="名称">
              <Input placeholder="请输入沙子包名称" value={status.name} onChange={event => {
            status.name = event.target.value;
        }}/>
            </Form.Item>
            <Form.Item label="数据形式">
              <Radio.Group value={status.sandMime} onChange={e => {
            status.sandMime = e.target.value;
        }}>
                <Radio value={ISand.Mime.Image}>图片</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="标签配置">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Upload accept=".json" showUploadList={false} beforeUpload={file => {
            labelSandBagsCreateStore.changeLabelFile(file);
            return false;
        }}>
                  <Button>
                    <UploadOutlined /> 上传标签配置文件
                  </Button>
                </Upload>
                {status.labelFilename && (<div style={{ marginLeft: "8px" }}>
                    {status.labelFilename}&nbsp;
                    <DeleteOutlined onClick={() => {
            labelSandBagsCreateStore.removeLabelFile();
        }}/>
                  </div>)}
              </div>
              {status.label && (<Card style={{ marginTop: "8px" }}>
                  <pre>{status.label}</pre>
                </Card>)}
            </Form.Item>
            <Form.Item label="数据文件">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Upload showUploadList={false} beforeUpload={file => {
            labelSandBagsCreateStore.changeSandFile(file);
            return false;
        }}>
                  <Button>
                    <UploadOutlined />
                    上传 jsonlist 文件
                  </Button>
                </Upload>
                {status.sandFilename && (<div style={{ marginLeft: "8px" }}>
                    {status.sandFilename}&nbsp;
                    <DeleteOutlined onClick={() => {
            labelSandBagsCreateStore.removeSandFile();
        }}/>
                  </div>)}
              </div>
              <Checkbox.Group value={toJS(status.propertySelected)} onChange={checkedValue => {
            status.propertySelected = checkedValue;
        }}>
                {status.propertySet.has("url") && (<Checkbox value="url" disabled={true}>
                    URL
                  </Checkbox>)}
                {status.propertySet.has("meta") && (<Checkbox value="meta" disabled={true}>
                    保留元信息 (meta)
                  </Checkbox>)}
                {status.propertySet.has("annotation") && (<Checkbox value="annotation" disabled={true}>
                    使用预标注 (annotation)
                  </Checkbox>)}
              </Checkbox.Group>
              <Table rowKey={record => record.url} size="small" style={{ marginTop: "8px" }} dataSource={status.displaySandUrls} columns={[{ title: "URL", dataIndex: "url", ellipsis: true, render: (value, record) => value }]} pagination={{ showSizeChanger: false }}/>
            </Form.Item>
            <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 20 }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                <Button onClick={() => {
            routerStore.goBack();
        }}>
                  取消
                </Button>
                <Button style={{ marginLeft: "10px" }} loading={status.isCreatingLabelSandBags} disabled={!labelSandBagsCreateStore.isEnableCreate} onClick={() => {
            labelSandBagsCreateStore.createLabelSandBagByUploaded();
        }}>
                  创建
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Card>
      </PageHeader>);
    }
};
LabelSandBagsCreate = __decorate([
    observer
], LabelSandBagsCreate);
export { LabelSandBagsCreate };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import moment from "moment";
import { CSVLink } from "react-csv";
import { Select, Table, DatePicker, Button } from "antd";
import { userAuditStore } from "./UserAuditStore";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { LabelTaskBusiness } from "definition/entity/label-task-business";
import { transformLabelPackageStatus } from "util/status-transform";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
let UserAudit = class UserAudit extends React.Component {
    constructor() {
        super(...arguments);
        this.columns = [
            {
                title: "用户名称",
                align: "center",
                width: 160,
                dataIndex: "username",
                ellipsis: true,
                render: (value, record) => (<span>
          {record === null || record === void 0 ? void 0 : record.fullname}({record === null || record === void 0 ? void 0 : record.email}){" "}
        </span>)
            },
            {
                title: "检测总量",
                align: "center",
                width: 120,
                render: (value, record) => `${record === null || record === void 0 ? void 0 : record.totalDetectionCount} (框)`
            },
            {
                title: "属性总量",
                align: "center",
                width: 120,
                render: (value, record) => `${record === null || record === void 0 ? void 0 : record.totalClassificationCount} (张)`
            },
            {
                title: "图像分割总量",
                align: "center",
                width: 120,
                render: (value, record) => `${record === null || record === void 0 ? void 0 : record.totalSegmentCount} (框)`
            },
            {
                title: "点云分割总量（非复用+复用）",
                align: "center",
                width: 240,
                render: (value, record) => record &&
                    `${record.totalSegment3dCount}(${record.totalSegment3dCount - record.totalInheritedSegment3dCount} + ${record.totalInheritedSegment3dCount}) (个)`
            },
            {
                title: "关键帧总数",
                align: "center",
                width: 120,
                render: (value, record) => `${record === null || record === void 0 ? void 0 : record.totalTrailCount} (个)`
            },
            {
                title: "对象总数",
                align: "center",
                width: 120,
                render: (value, record) => `${record === null || record === void 0 ? void 0 : record.totalObjectCount} (个)`
            },
            {
                title: "分类内容总数",
                align: "center",
                width: 120,
                render: (value, record) => `${record === null || record === void 0 ? void 0 : record.totalChunkCount} (段)`
            },
            {
                title: "总时长(s)",
                align: "center",
                width: 120,
                render: (value, record) => `${record === null || record === void 0 ? void 0 : record.totalDuration.toFixed(2)}`
            }
        ];
        this.childColumns = [
            {
                title: "包名",
                dataIndex: "id",
                width: 160,
                ellipsis: true,
                render: (value, record) => { var _a; return (_a = record === null || record === void 0 ? void 0 : record.labelPackage) === null || _a === void 0 ? void 0 : _a.name; }
            },
            {
                title: "状态",
                width: 120,
                render: (value, record) => {
                    return transformLabelPackageStatus(record.labelPackage);
                }
            },
            {
                title: "所属任务",
                width: 160,
                ellipsis: true,
                render: (value, record) => { var _a; return (_a = record === null || record === void 0 ? void 0 : record.labelTask) === null || _a === void 0 ? void 0 : _a.name; }
            },
            {
                title: "计价类型",
                width: 120,
                ellipsis: true,
                render: (value, record) => { var _a; return ((_a = record === null || record === void 0 ? void 0 : record.labelTaskBusiness) === null || _a === void 0 ? void 0 : _a.name) || "未分配类型"; }
            },
            {
                title: "检测量",
                align: "center",
                width: 120,
                render: (value, record) => {
                    var _a;
                    if (((_a = record === null || record === void 0 ? void 0 : record.labelTaskBusiness) === null || _a === void 0 ? void 0 : _a.type) === LabelTaskBusiness.Type.Box) {
                        return <span>{record === null || record === void 0 ? void 0 : record.detectionCount} (框)</span>;
                    }
                    else {
                        return <span>-</span>;
                    }
                }
            },
            {
                title: "属性量",
                align: "center",
                width: 120,
                render: (value, record) => {
                    var _a;
                    if (((_a = record === null || record === void 0 ? void 0 : record.labelTaskBusiness) === null || _a === void 0 ? void 0 : _a.type) === LabelTaskBusiness.Type.Piece) {
                        return <span>{record === null || record === void 0 ? void 0 : record.classificationCount} (张)</span>;
                    }
                    else {
                        return <span>-</span>;
                    }
                }
            },
            {
                title: "图像分割量",
                align: "center",
                width: 120,
                render: (value, record) => {
                    return record === null || record === void 0 ? void 0 : record.segmentCount;
                }
            },
            {
                title: "点云分割总量（非复用+复用）",
                align: "center",
                width: 240,
                render: (value, record) => record &&
                    `${record.segment3dCount}(${record.segment3dCount - record.inheritedSegment3dCount} + ${record.inheritedSegment3dCount}) (个)`
            },
            {
                title: "关键帧数",
                align: "center",
                width: 120,
                render: (value, record) => {
                    var _a, _b;
                    if (((_a = record === null || record === void 0 ? void 0 : record.labelTaskBusiness) === null || _a === void 0 ? void 0 : _a.type) === LabelTaskBusiness.Type.Object) {
                        return <span>{record === null || record === void 0 ? void 0 : record.trailCount}</span>;
                    }
                    else if (((_b = record === null || record === void 0 ? void 0 : record.labelTaskBusiness) === null || _b === void 0 ? void 0 : _b.type) === LabelTaskBusiness.Type.Anchor) {
                        return <span>{record === null || record === void 0 ? void 0 : record.anchorTrailCount}</span>;
                    }
                    else {
                        return 0;
                    }
                }
            },
            {
                title: "对象数",
                align: "center",
                width: 120,
                render: (value, record) => {
                    var _a, _b;
                    if (((_a = record === null || record === void 0 ? void 0 : record.labelTaskBusiness) === null || _a === void 0 ? void 0 : _a.type) === LabelTaskBusiness.Type.Object) {
                        return <span>{record === null || record === void 0 ? void 0 : record.objectCount}</span>;
                    }
                    else if (((_b = record === null || record === void 0 ? void 0 : record.labelTaskBusiness) === null || _b === void 0 ? void 0 : _b.type) === LabelTaskBusiness.Type.Anchor) {
                        return <span>{record === null || record === void 0 ? void 0 : record.anchorCount}</span>;
                    }
                    else {
                        return 0;
                    }
                }
            },
            {
                title: "分类内容数",
                align: "center",
                width: 120,
                render: (value, record) => {
                    return record === null || record === void 0 ? void 0 : record.chunkCount;
                }
            },
            {
                title: "时长(s)",
                align: "center",
                width: 120,
                render: (value, record) => {
                    return record === null || record === void 0 ? void 0 : record.duration;
                }
            }
        ];
        this.csvHeaders = [
            {
                label: "用户名称",
                key: "username"
            },
            {
                label: "包名",
                key: "packageName"
            },
            {
                label: "状态",
                key: "packageStatus"
            },
            {
                label: "所属任务",
                key: "taskName"
            },
            {
                label: "计价类型",
                key: "businessName"
            },
            {
                label: "计价单位",
                key: "businessType"
            },
            {
                label: "检测量",
                key: "detectionCount"
            },
            {
                label: "属性量",
                key: "classificationCount"
            },
            {
                label: "图像分割量",
                key: "segmentCount"
            },
            {
                label: "点云分割量(非复用+复用)",
                key: "segment3DCount"
            },
            {
                label: "关键帧数",
                key: "trailCount"
            },
            {
                label: "对象数",
                key: "objectCount"
            },
            {
                label: "分类内容数",
                key: "chunkCount"
            },
            {
                label: "时长(s)",
                key: "duration"
            }
        ];
        this.expandedRowRender = (record) => {
            const status = userAuditStore.status;
            const childData = status.userAudits.filter(item => { var _a; return record.userId === ((_a = item.user) === null || _a === void 0 ? void 0 : _a.id); });
            return (<Table rowKey={record => "detail" + record.id} columns={this.childColumns} dataSource={childData} pagination={false}/>);
        };
    }
    componentDidMount() {
        userAuditStore.loadGroups();
        userAuditStore.loadUsers();
        userAuditStore.loadData();
    }
    render() {
        const status = userAuditStore.status;
        return (<div>
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        }}>
          <div>
            <DatePicker placeholder="选择日" style={{
            width: 240
        }} disabledDate={current => {
            return (current &&
                current >=
                    moment()
                        .startOf("day")
                        .add(1, "day"));
        }} value={status.calendarDate} onChange={date => {
            status.calendarDate = date;
        }}/>
            <Select showSearch allowClear mode="multiple" placeholder="请选择单位" style={{ width: 240, marginLeft: 10 }} value={toJS(status.curGroupIds)} onChange={value => {
            status.curGroupIds = value;
            status.curUserIds = [];
        }} filterOption={(input, option) => {
            if (option && option.props && option.props.title) {
                return option.props.title === input || option.props.title.indexOf(input) !== -1;
            }
            else {
                return true;
            }
        }}>
              {status.groups.map(group => (<Select.Option key={group.id} title={group.name} value={group.id}>
                  {group.name}
                </Select.Option>))}
            </Select>
            <Select placeholder="请选择用户" showSearch mode="multiple" value={toJS(status.curUserIds)} style={{ width: 300, marginLeft: 10 }} onChange={value => {
            status.curUserIds = value;
        }} filterOption={(input, option) => {
            if (option && option.props && option.props.title) {
                return option.props.title === input || option.props.title.indexOf(input) !== -1;
            }
            else {
                return true;
            }
        }}>
              {toJS(userAuditStore.filterUsers).map(user => (<Select.Option key={user.id} title={`${user.name}`} value={user.id}>
                  {`${user.name}`}
                </Select.Option>))}
            </Select>
            <Button style={{
            marginLeft: 10
        }} icon={<SearchOutlined />} disabled={userAuditStore.isUnableSearch} onClick={() => {
            userAuditStore.loadData();
        }}></Button>
          </div>

          <CSVLink data={toJS(status.exportCSVData)} filename={status.CSVFilename} headers={this.csvHeaders}>
            <Button type="primary" shape="round" icon={<DownloadOutlined />} disabled={userAuditStore.isUnableDownload} onClick={() => userAuditStore.downAnnotationAuditFile()}/>
          </CSVLink>
        </div>

        <Table loading={status.loading} scroll={{ x: true }} rowKey={record => "workload" + record.userId} bordered={true} style={{ marginTop: "20px" }} dataSource={toJS(userAuditStore.transformedAnnotation)} columns={this.columns} expandedRowRender={this.expandedRowRender}/>
      </div>);
    }
};
UserAudit = __decorate([
    observer
], UserAudit);
export { UserAudit };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as _ from "lodash";
import moment from "moment";
import BasicStore from "store/BasicStore";
import { action, observable, computed, toJS } from "mobx";
import { Api } from "api/Api";
import { message } from "antd";
import { transformLabelPackageStatus } from "util/status-transform";
class Status {
    constructor() {
        this.loading = false;
        this.comprehensiveAudits = [];
        this.groups = [];
        this.users = [];
        this.exportCSVData = [];
        this.CSVFilename = undefined;
        this.curGroupIds = [];
        this.curUserIds = [];
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "loading", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "comprehensiveAudits", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "groups", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "users", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "exportCSVData", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "CSVFilename", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "curGroupIds", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "curUserIds", void 0);
class ComprehensiveAuditStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
    }
    resetStatus() {
        this.status = new Status();
    }
    async loadData() {
        const { curGroupIds, curUserIds } = toJS(this.status);
        try {
            this.status.loading = true;
            const comprehensiveAudits = await Api.audit.getComprehensiveAudits({
                groupIds: curGroupIds,
                userIds: curUserIds
            });
            this.status.comprehensiveAudits = comprehensiveAudits.data;
            this.status.CSVFilename = `comprehensiveAudits`;
        }
        catch (err) {
            message.error(err.message);
        }
        finally {
            this.status.loading = false;
        }
    }
    async loadGroups() {
        const getGroupsRes = await Api.membership.listGroups();
        this.status.groups = getGroupsRes.data;
    }
    async loadUsers() {
        const getUsersRes = await Api.user.listUsers();
        this.status.users = getUsersRes.data;
    }
    downAnnotationAuditFile() {
        const { comprehensiveAudits, exportCSVData } = this.status;
        exportCSVData.length = 0;
        comprehensiveAudits.forEach(audit => {
            var _a, _b;
            const startLabelTime = (_a = audit === null || audit === void 0 ? void 0 : audit.timeline) === null || _a === void 0 ? void 0 : _a.find(item => { var _a; return (_a = item[1]) === null || _a === void 0 ? void 0 : _a.includes("开始标注"); });
            exportCSVData.push({
                username: `${audit === null || audit === void 0 ? void 0 : audit.nickname}(${audit === null || audit === void 0 ? void 0 : audit.username})`,
                packageName: audit.packageName,
                packageStatus: transformLabelPackageStatus(audit),
                taskName: audit.taskName,
                labelProcess: `${((audit.labeledResourceLen / audit.totalResourceLen) * 100).toFixed(2)}% (${audit.labeledResourceLen}/${audit.totalResourceLen})`,
                startLabelTime: startLabelTime ? (_b = moment(startLabelTime[0])) === null || _b === void 0 ? void 0 : _b.format("YYYY-MM-DD") : "-"
            });
        });
    }
    get transformedAnnotation() {
        const annotationAuditsMap = new Map();
        this.status.comprehensiveAudits.forEach(audit => {
            if (annotationAuditsMap.has(audit.userId)) {
                annotationAuditsMap.get(audit.userId).totalPackageCount += 1;
                const status = transformLabelPackageStatus(audit);
                if (status.includes("标注中")) {
                    annotationAuditsMap.get(audit.userId).totalLabelPackageCount += 1;
                }
                if (status.includes("修改中")) {
                    annotationAuditsMap.get(audit.userId).totalModifyPackageCount += 1;
                }
                if (status.includes("复审中")) {
                    annotationAuditsMap.get(audit.userId).totalReviewPackageCount += 1;
                }
            }
            else {
                const auditMapValue = {
                    userId: audit.userId,
                    fullname: audit.fullname,
                    email: audit.email,
                    totalPackageCount: 1,
                    totalLabelPackageCount: 0,
                    totalModifyPackageCount: 0,
                    totalReviewPackageCount: 0
                };
                const status = transformLabelPackageStatus(audit);
                if (status.includes("标注中")) {
                    auditMapValue.totalLabelPackageCount = 1;
                }
                if (status.includes("修改中")) {
                    auditMapValue.totalModifyPackageCount = 1;
                }
                if (status.includes("复审中")) {
                    auditMapValue.totalReviewPackageCount = 1;
                }
                annotationAuditsMap.set(audit.userId, auditMapValue);
            }
        });
        return [...annotationAuditsMap.values()];
    }
    get isUnableDownload() {
        return _.isEmpty(toJS(this.status.comprehensiveAudits));
    }
    get isUnableSearch() {
        return (this.status.curGroupIds.length === 0 && this.status.curUserIds.length === 0) || this.status.loading;
    }
    get filterUsers() {
        const { users, curGroupIds } = toJS(this.status);
        return users;
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], ComprehensiveAuditStore.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ComprehensiveAuditStore.prototype, "resetStatus", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], ComprehensiveAuditStore.prototype, "transformedAnnotation", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], ComprehensiveAuditStore.prototype, "isUnableDownload", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], ComprehensiveAuditStore.prototype, "isUnableSearch", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], ComprehensiveAuditStore.prototype, "filterUsers", null);
export const comprehensiveAuditStore = new ComprehensiveAuditStore();
